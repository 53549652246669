import { Breadcrumb } from "@hexa-ui/components";
import { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";

const BreadCrumb = (): JSX.Element => {
  const location = useLocation();
  const pathnames = location.pathname
    .split("/")
    .filter(
      (path) => path !== "" && Object.keys(BreadcrumbLabels).includes(path)
    );

  return (
    <Breadcrumb.Root>
      <Breadcrumb.HomeItem asChild>
        <Link to={"/"} />
      </Breadcrumb.HomeItem>
      {pathnames.map((path, index) => {
        return (
          <Fragment key={path}>
            {path && path !== "" && (
              <Breadcrumb.Item
                asChild
                isCurrentPage={path === pathnames[pathnames.length - 1]}
              >
                <Link to={`/${path}`}>
                  <FormattedMessage
                    id={`bread_crumb.${BreadcrumbLabels[path]}`}
                  />
                </Link>
              </Breadcrumb.Item>
            )}
          </Fragment>
        );
      })}
    </Breadcrumb.Root>
  );
};

export enum BreadcrumbLabels {
  "experimentation-platform" = "experimentation_platform",
  "new-experiment" = "new_experiment",
  "experiment" = "experiment_details",
  "edit" = "edit_experiment",
}

export default BreadCrumb;
