// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-heading-align {
  display: flex;
  justify-content: space-between !important;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
}

.default-link {
  display: flex;
  text-decoration: none;
  font-weight: 500;
  margin-top: 10px;
}

.medium-link {
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yCAAyC;EACzC,oCAAoC;EACpC,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".card-heading-align {\n  display: flex;\n  justify-content: space-between !important;\n  -webkit-box-pack: justify !important;\n  -ms-flex-pack: justify !important;\n}\n\n.default-link {\n  display: flex;\n  text-decoration: none;\n  font-weight: 500;\n  margin-top: 10px;\n}\n\n.medium-link {\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
