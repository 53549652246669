import {
  Button,
  Calendar,
  Dialog,
  Heading,
  Paragraph,
  TextLink,
} from "@hexa-ui/components";
import { FormattedMessage } from "react-intl";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
} from "react-query";
import { updateExpriment } from "../../services/experiment/experimentService";
import { IExperiment } from "../../types/experiment";

const EstimatedDateDialog = ({
  isEstimatedEndDateDialogOpen,
  setIsEstimatedEndDateDialogOpen,
  estimatedEndDate,
  setEstimatedEndDate,
  experiment,
  refetchGetExperimentById,
}: EstimatedDateDialogProps) => {
  const dataUpdate = useMutation({
    mutationFn: (formData: IExperiment) => updateExpriment(formData),
    mutationKey: ["updateExperiment", experiment?.id],
  });

  const handleConfirm = async () => {
    await dataUpdate.mutateAsync({
      ...experiment,
      endDate: estimatedEndDate,
    });
    setIsEstimatedEndDateDialogOpen(false);
    refetchGetExperimentById();
  };

  const editDate = (date) => {
    setEstimatedEndDate(date);
  };
  return (
    <>
      <Dialog.Root
        closeButton={false}
        contentCss={{ overflow: "unset" }}
        actions={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => setIsEstimatedEndDateDialogOpen(false)}
              data-testid={`estimated-date-dialog-close`}
              size="medium"
              style={{ marginRight: "0.9375rem" }}
              variant="secondary"
            >
              <FormattedMessage
                id={`experiment_details.edit_estimated_end_date.cancel`}
              />
            </Button>

            <Button
              data-testid="estimated-date-dialog-continue"
              onClick={handleConfirm}
              size="medium"
              variant="primary"
            >
              <FormattedMessage
                id={`experiment_details.edit_estimated_end_date.continue`}
              />
            </Button>
          </div>
        }
        title={
          <Heading size="H2">
            <FormattedMessage id="experiment_details.edit_estimated_end_date.title" />
          </Heading>
        }
        open={isEstimatedEndDateDialogOpen}
      >
        <Paragraph style={{ paddingBottom: "16px" }}>
          <FormattedMessage id="experiment_details.edit_estimated_end_date.cxl_calculator.previous_text" />{" "}
          <br />
          <TextLink target="blank" href="https://cxl.com/ab-test-calculator/">
            <FormattedMessage id="experiment_details.edit_estimated_end_date.cxl_calculator.link" />
          </TextLink>
          .
        </Paragraph>
        <Paragraph style={{ display: "flex", justifyContent: "center" }}>
          <Calendar
            data-testid="estimated-end-date-calendar"
            onChange={editDate}
            mode="single"
            disabled={{ before: new Date() }}
          />
        </Paragraph>
      </Dialog.Root>
    </>
  );
};

type EstimatedDateDialogProps = {
  isEstimatedEndDateDialogOpen: boolean;
  setIsEstimatedEndDateDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  estimatedEndDate: Date;
  setEstimatedEndDate: React.Dispatch<React.SetStateAction<Date>>;
  experiment: IExperiment;
  refetchGetExperimentById: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<IExperiment, unknown>>;
};

export default EstimatedDateDialog;
