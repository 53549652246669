import { ExperimentStatusProp } from "@/types/experiment";
import { BadgeStatusTypes } from "@hexa-ui/components";
import { ExperimentStatusObject } from "../types/common";

interface experimentStatus {
  color: BadgeStatusTypes;
  label: string;
}

export const getExperimentStatus = (
  status: ExperimentStatusProp
): experimentStatus => {
  return ExperimentStatusObject[status] || ExperimentStatusObject.default;
};

export const formatedDate = (date?: Date): string => {
  if (!date) return "-";
  const dateObj = new Date(date);
  return new Intl.DateTimeFormat("en-US", {
    dateStyle: "medium",
  })
    .format(dateObj)
    .toString();
};

export const isNotItEmptyOrNull = (
  value: string | null | undefined
): boolean => {
  return value !== null && value !== undefined && value !== "";
};
