import { Button, Dialog, Heading, Input, Paragraph } from "@hexa-ui/components";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { setExperimentMutation } from "../../store/ducks/ExperimentMutation/ExperimentMutationDuck";
import { useExperimentMutation } from "../../store/hooks/ExperimentMutation/ExperimentMutationHook";
type Props = {
  openDialog: {
    status: boolean;
    variantId: string;
  };
  handleAddViewer: () => void;
  handleGoBack: () => void;
  variantId: string;
};
const PocIdsDialog = ({
  openDialog,
  handleAddViewer,
  handleGoBack,
  variantId,
}: Props) => {
  const [pocId, setPocId] = useState("");
  const [isDialogInputValid, setIsDialogInputValid] = useState<any>({
    status: true,
    message: "",
  });
  const invalidPocId = (poc: string) => {
    setPocId(poc);

    let isValid = {
      status: true,
      message: "",
    };
    const currentPocs = variants.map((variant) => variant.viewers).flat();
    const hasPocId = currentPocs.includes(poc);
    if (hasPocId) {
      isValid = {
        status: false,
        message: "This POC ID has already been added. Try another one.",
      };
    }

    setIsDialogInputValid(isValid);
  };
  const {
    experimentMutation: { variations: variants },
  } = useExperimentMutation();

  const dispatch = useDispatch();

  const addViewer = () => {
    let inputValid = {
      status: true,
      message: "",
    };
    if (!pocId) {
      inputValid = {
        status: false,
        message: "You must enter a POC ID.",
      };
      setIsDialogInputValid(inputValid);
      return;
    }

    dispatch(
      setExperimentMutation({
        variations: [
          ...variants.map((variant, i) => {
            if (variant?.id === variantId) {
              return {
                ...variant,
                viewers: [...variant.viewers, `${pocId}`],
              };
            }
            return variant;
          }),
        ],
      })
    );

    setPocId("");
    handleAddViewer();
  };

  return (
    <Dialog.Root
      open={openDialog.status}
      closeButton={false}
      title={
        <Heading data-testid="dialog-header-add-poc-id">
          <FormattedMessage id="create_and_update_experiment.labels.step3.variants.section_allow_list.title" />
        </Heading>
      }
    >
      <Paragraph>
        <FormattedMessage id="create_and_update_experiment.labels.step3.modal.dialog.description" />
      </Paragraph>
      <div style={{ marginTop: 30, marginBottom: 30 }}>
        <Input
          id="dialog-input-poc-id"
          data-testid="dialog-input-poc-id"
          label="POC ID"
          placeholder="Enter POC ID"
          hasError={!isDialogInputValid.status}
          errorText={isDialogInputValid.message}
          width={"100%"}
          name="pocId"
          value={pocId}
          onChange={(event: any) => invalidPocId(event.target.value)}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          size="medium"
          variant="secondary"
          data-testid="dialog-go-back-button"
          style={{ marginRight: 10 }}
          type="button"
          onClick={() => {
            handleGoBack();
          }}
        >
          Go Back
        </Button>

        <Button
          size="medium"
          disabled={!isDialogInputValid.status}
          variant="primary"
          data-testid={`dialog-button-add`}
          type="button"
          onClick={() => {
            addViewer();
          }}
        >
          Add
        </Button>
      </div>
    </Dialog.Root>
  );
};

export default PocIdsDialog;
