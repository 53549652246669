import { useLocation, useNavigate } from "react-router-dom";

export const useQuery = () => {
  const { search } = window.location;
  return new URLSearchParams(search);
};

export const useCustomHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return {
    goBack: () => navigate(-1),
    goTo: (path: string) => navigate(`/experimentation-platform/${path}`),
    currentPath: location.pathname,
  };
};
