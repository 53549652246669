import {
  Alert,
  Card,
  Checkbox,
  Divider,
  Grid,
  Heading,
  Input,
  Paragraph,
  TextLink,
} from "@hexa-ui/components";
import { HelpCircle } from "@hexa-ui/icons";
import { Fragment, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import AudienceSelector from "../../../components/AudienceSelector/AudienceSelector";
import CustomCreateNewAudienceDialog from "../../../components/CustomCreateNewAudienceDialog/CustomCreateNewAudienceDialog";
import { setExperimentMutation } from "../../../store/ducks/ExperimentMutation/ExperimentMutationDuck";
import { useExperimentMutation } from "../../../store/hooks/ExperimentMutation/ExperimentMutationHook";
import {
  ExperimentInputErrors,
  IExperimentationAudience,
} from "../../../types/experiment";
type Props = {
  inputError: string[];
  setinputFormError: React.Dispatch<
    React.SetStateAction<ExperimentInputErrors>
  >;
};

const Step2 = ({ inputError, setinputFormError }: Props): JSX.Element => {
  const { Container, Item } = Grid;

  const intl = useIntl();

  const handleTrafficPercentage = (value: number) => {
    if (value > 100) return "100";
    if (value < 0) return "0";
    return String(value);
  };

  const {
    experimentMutation: {
      audiences,
      basicInformation: { country },
    },
  } = useExperimentMutation();
  const dispatch = useDispatch();

  const handleOnChangeForm = (
    inputName?: string,
    value?: string | number | boolean | IExperimentationAudience
  ) => {
    if (inputName === "trafficPercentage") {
      value = handleTrafficPercentage(Number(value));
    }

    dispatch(
      setExperimentMutation({ audiences: { ...audiences, [inputName]: value } })
    );

    const field =
      (inputName === "countryAsAudience" && value) || inputName === "audiences"
        ? "noAudience"
        : inputName;

    const index = inputError.indexOf(field);
    inputError.splice(index, 1);

    const errors = {
      step1: [],
      step2: inputError,
      step3: {
        errors: false,
        variant_a: [],
        variant_b: [],
        variant_c: [],
        variant_d: [],
        variant_e: [],
        variant_f: [],
      },
    };
    setinputFormError(errors);
  };

  const [openCreateNewAudienceDialog, setOpenCreateNewAudienceDialog] =
    useState(false);

  return (
    <Fragment>
      <Item data-testid="step-2" sm={12}>
        <CustomCreateNewAudienceDialog
          openCreateNewAudienceDialog={openCreateNewAudienceDialog}
          setOpenCreateNewAudienceDialog={setOpenCreateNewAudienceDialog}
        />
        <Card
          ref={{
            current: "[Circular]" as any,
          }}
          border="medium"
          elevated="small"
          style={{
            marginTop: 50,
            padding: 20,
            width: "100%",
          }}
        >
          <Container
            style={{
              rowGap: "1rem",
              width: "100%",
            }}
          >
            <Item>
              <Heading size="H3">
                <FormattedMessage id="create_and_update_experiment.page_title_section.step2.audience_selection" />
              </Heading>
            </Item>

            {inputError?.includes("noAudience") && (
              <Alert
                css={{ width: "100%" }}
                message="You must select an audience"
                type="error"
              />
            )}
            <Item lg={12} md={12} sm={12} xl={12} xs={12}>
              <div
                style={{
                  height: "42px",
                  gap: "0 24px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  id="checkbox-country-as-audience"
                  data-testid="checkbox-country-as-audience"
                  label={`${intl.formatMessage({ id: "create_and_update_experiment.audience.selected_country_checkbox" })}`}
                  checked={audiences?.countryAsAudience}
                  onClick={() =>
                    handleOnChangeForm(
                      "countryAsAudience",
                      !audiences.countryAsAudience
                    )
                  }
                  value={audiences?.countryAsAudience.toString()}
                />
                <Divider orientation="vertical" />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "14px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    <FormattedMessage id="create_and_update_experiment.labels.step2.selected_country" />
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    <FormattedMessage id={`countries.${country}.label`} />
                  </span>
                </div>
              </div>
            </Item>

            <Container
              style={{
                rowGap: "1rem",
                width: "100%",
              }}
            >
              {!audiences?.countryAsAudience && (
                <Item lg={6} md={6} sm={12} xl={6} xs={12}>
                  <div style={{ width: "100%" }}>
                    <div style={{ gap: "24px" }}>
                      <>
                        <AudienceSelector
                          handleOnChangeForm={handleOnChangeForm}
                          step2Form={{ ...audiences, country }}
                        />
                        <Item
                          lg={12}
                          md={12}
                          sm={12}
                          xl={12}
                          xs={12}
                          style={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            paddingTop: 12,
                          }}
                        >
                          {" "}
                          <HelpCircle
                            style={{
                              color: "rgb(3,99,196)",
                              marginRight: "5px",
                            }}
                          />
                          <TextLink
                            size="basis"
                            onClick={() => setOpenCreateNewAudienceDialog(true)}
                            hasUnderline={false}
                            style={{ marginTop: 4 }}
                            data-testid="create-new-audience-link"
                          >
                            <FormattedMessage id="create_and_update_experiment.audience.create_a_new_audience" />
                          </TextLink>
                        </Item>
                      </>
                    </div>
                  </div>
                </Item>
              )}
            </Container>
          </Container>
        </Card>
      </Item>
      <Item sm={12}>
        <Card
          ref={{
            current: "[Circular]" as any,
          }}
          border="medium"
          elevated="small"
          style={{
            marginTop: 50,
            padding: 20,
            width: "100%",
          }}
        >
          <Container
            style={{
              rowGap: "1rem",
              width: "100%",
            }}
          >
            <Item lg={12} md={12} sm={12} xl={12} xs={12}>
              <div>
                <Heading size="H3">
                  <FormattedMessage id="create_and_update_experiment.page_title_section.step2.traffic_allocation.title" />
                </Heading>
                <Paragraph size="basis" colortype="disabled">
                  <FormattedMessage id="create_and_update_experiment.page_title_section.step2.traffic_allocation.sub_title" />
                </Paragraph>
                <div style={{ marginTop: 20, width: "100%" }}>
                  <Input
                    data-testid="input-audience-rollout"
                    id="input-audience-rollout"
                    label={`${intl.formatMessage({ id: "create_and_update_experiment.labels.step2.allocation_percentage" })}`}
                    sufix="%"
                    type="number"
                    max="100"
                    min="0"
                    placeholder={`${intl.formatMessage({ id: "create_and_update_experiment.placeholder.step2.allocation_percentage" })}`}
                    name={"trafficPercentage"}
                    hasError={inputError?.includes("trafficPercentage")}
                    value={audiences?.trafficPercentage}
                    errorText={intl.formatMessage({
                      id: "create_and_update_experiment.error_messages.allocation_percentage",
                    })}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleOnChangeForm("trafficPercentage", e.target.value)
                    }
                  />
                </div>
              </div>
            </Item>
          </Container>
        </Card>
      </Item>
    </Fragment>
  );
};

export default Step2;
