import { LoadingDots } from "@hexa-ui/components";
import { createContext, useContext, useState } from "react";

import "./styles.scss";

const initialState = {
  isLoading: false,
  showLoading: () => {},
  hideLoading: () => {},
};

const LoadingContext = createContext(initialState);
export const useLoading = () => useContext(LoadingContext);

const LoadingProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false);

  const showLoading = () => setLoading(true);
  const hideLoading = () => setLoading(false);

  return (
    <LoadingContext.Provider value={{ isLoading, showLoading, hideLoading }}>
      {children}

      {isLoading && (
        <div className="loading">
          <div className="loading__content">
            <div className="loading__content__spinner">
              <LoadingDots />
            </div>
            <div className="loading__content__text">
              Loading data. Wait a moment.
            </div>
          </div>
        </div>
      )}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
