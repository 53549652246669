import Api from "../../Api/Api";
import { envs } from "../../config/env";
import { IExperiment } from "../../types/experiment";

export async function getAudienceByName(experiment: IExperiment): Promise<any> {
  const audienceName = !!experiment?.audiences?.length
    ? experiment?.audiences[0].name
    : null;
  try {
    const response = await Api.get({
      url: `${envs.AUDIENCE_BUILDER}?audienceName=${audienceName}&status=PROCESSED`,
      config: {
        headers: {
          country: experiment?.country,
          vendorId: experiment?.vendorId,
        },
      },
    });
    const res = response?.code ? "" : response.data.content[0];
    return res;
  } catch (error) {
    console.error("Error retrieving experiment:", error);
    throw error;
  }
}
