import { Button, Card, Grid } from "@hexa-ui/components";
import { ArrowRight, Clipboard, Download, Edit2 } from "@hexa-ui/icons";
import { TypeToast } from "admin-portal-shared-services";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import CustomDialog from "../../components/CustomDialog/CustomDialog";
import CustomToast from "../../components/CustomToast/CustomToast";
import EnterResultsDialog from "../../components/EnterResultsDialog/EnterResultsDialog";
import EstimatedDateDialog from "../../components/EstimatedDateDialog/EstimatedDateDialog";
import AudienceSelect from "../../components/ExperimentCommon/AudienceSelect/AudienceSelect";
import BasicInformation from "../../components/ExperimentCommon/BasicInformation/BasicInformation";
import ExperimentSchedule from "../../components/ExperimentCommon/ExperimentSchedule.tsx/ExperimentSchedule";
import ExperimentStatus from "../../components/ExperimentCommon/ExperimentStatus.tsx/ExperimentStatus";
import Metrics from "../../components/ExperimentCommon/Metrics/Metrics";
import Variants from "../../components/ExperimentCommon/Variants/Variants";
import ExperimentResults from "../../components/ExperimentResults/ExperimentResults";
import ExportFileDialog from "../../components/ExportFileDialog/ExportFileDialog";
import CustomPageHeader from "../../components/molecules/CustomPageheader/CustomPageHeader";
import { useExportExperimentQuery } from "../../hooks/queries/experiments/useExportExperimentQuery/useExportExperimentQuery";
import useResizeObserverErrorHandler from "../../hooks/useResizeObserverErrorHandler/useResizeObserverErrorHandler";
import useToastConfig from "../../hooks/useToast/useToastConfig";
import { getExperimentById } from "../../services/experiment/experimentService";
import { isNotItEmptyOrNull } from "../../services/utils";
import "../../style.css";
import { useCustomHistory } from "../../utils/routes";
import { SET_TIMEOUT_TOAST_DURATION } from "../ExperimentMutation/utils";
import { generateJsonFile } from "../ExperimentsList/utils/utils";

const ExperimentDetails = (): JSX.Element => {
  useResizeObserverErrorHandler();
  const { Item } = Grid;

  const navigate = useCustomHistory();
  const { id: experimentId } = useParams();

  const { data: experiment, refetch: refetchGetExperimentById } = useQuery({
    queryFn: () => getExperimentById(experimentId),
    queryKey: ["detailsExperiment", experimentId],
    enabled: !!experimentId,
    refetchOnWindowFocus: false,
    retry: 5,
  });

  const exportQuery = useExportExperimentQuery();

  const { toast, handleToast, handleCloseToast } = useToastConfig();

  const handleEditExperiment = () => {
    navigate.goTo(`edit/${experimentId}`);
  };

  const editButton = (
    <Button
      icon={Edit2}
      data-testid="button-edit-experiment"
      size="medium"
      type="button"
      leading
      className="mr-1"
      onClick={() => handleEditExperiment()}
    >
      Edit experiment
    </Button>
  );

  const enterResultsButton = (
    <Button
      icon={Clipboard}
      data-testid="button-enter-experiment-results"
      size="medium"
      type="button"
      leading
      className="mr-1"
      onClick={() => setEnterResultsOpen(true)}
    >
      Enter results
    </Button>
  );

  const exportExperimentJSONFileButton = (
    <Button
      icon={Download}
      size="medium"
      type="button"
      leading
      className="mr-1"
      variant="secondary"
      data-testid="button-export-experiment"
      onClick={() => exportQuery.exportJson(experimentId)}
    >
      <FormattedMessage id="experiment_details.buttons.export_json_file" />
    </Button>
  );
  const endExperiment = (
    <Button
      icon={ArrowRight}
      size="medium"
      type="button"
      leading
      className="mr-1"
      variant="primary"
      data-testid="button-end-experiment"
      onClick={() => setHandleModal(true)}
    >
      <FormattedMessage id="experiment_details.buttons.end_experiment" />
    </Button>
  );

  const [isEstimatedEndDateDialogOpen, setIsEstimatedEndDateDialogOpen] =
    useState(false);
  const [estimatedEndDate, setEstimatedEndDate] = useState(new Date());

  const actionArea = () => {
    const status = experiment?.status + "";

    switch (status) {
      case "DRAFT":
        return (
          <div style={{ display: "flex" }}>
            {exportExperimentJSONFileButton}
            {editButton}
          </div>
        );
      case "FINISHED":
        return experiment.result ? (
          exportExperimentJSONFileButton
        ) : (
          <div style={{ display: "flex" }}>
            {exportExperimentJSONFileButton}
            {enterResultsButton}
          </div>
        );
      case "STARTED":
        return (
          <div style={{ display: "flex" }}>
            {exportExperimentJSONFileButton}
            {endExperiment}
          </div>
        );
      default:
        return exportExperimentJSONFileButton;
    }
  };

  const [handleModal, setHandleModal] = useState(false);
  const [enterResultsOpen, setEnterResultsOpen] = useState(false);

  const queryClient = useQueryClient();

  const handleCancelExportJSON = () => {
    queryClient.resetQueries({ queryKey: ["export-experiment"] });
  };

  useEffect(() => {
    if (exportQuery.isSuccess) {
      generateJsonFile(exportQuery.data, exportQuery.data?.name);
      handleToast(
        "File successfully exported.",
        true,
        TypeToast.SUCCESS,
        SET_TIMEOUT_TOAST_DURATION
      );
      exportQuery.setExperimentId("");
    }
  }, [exportQuery.isSuccess]);

  return (
    <Item
      sm={12}
      md={12}
      lg={12}
      xl={12}
      xs={12}
      style={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "16px",
        paddingBottom: "16px",
        gap: "16px 0",
      }}
    >
      <EstimatedDateDialog
        refetchGetExperimentById={refetchGetExperimentById}
        experiment={experiment}
        estimatedEndDate={estimatedEndDate}
        setEstimatedEndDate={setEstimatedEndDate}
        isEstimatedEndDateDialogOpen={isEstimatedEndDateDialogOpen}
        setIsEstimatedEndDateDialogOpen={setIsEstimatedEndDateDialogOpen}
      />

      <EnterResultsDialog
        refetchGetExperimentById={refetchGetExperimentById}
        setEnterResultsOpen={setEnterResultsOpen}
        enterResultsOpen={enterResultsOpen}
        experiment={experiment}
      />

      <CustomDialog
        refetchGetExperimentById={refetchGetExperimentById}
        handleToast={handleToast}
        experimentId={experimentId}
        handleModal={handleModal}
        setHandleModal={setHandleModal}
      />

      <CustomToast
        dataTestid="custom-toast-dialog"
        isOpened={toast.isOpened}
        duration={toast.duration}
        onClose={handleCloseToast}
        type={toast.type}
        showCloseButton={true}
        message={toast.message}
      />

      <ExportFileDialog
        open={exportQuery.isLoading}
        title={"exporting_file"}
        messages={["this_may_take_a_few_minutes", "do_not_close_this_window"]}
        close={handleCancelExportJSON}
      />

      <CustomPageHeader title={experiment?.name} actions={actionArea()} />
      <Item
        style={{ display: "flex", flexDirection: "column" }}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        xs={12}
        data-testid="step-4"
      >
        <Item sm={12} md={12} lg={12} xl={12} xs={12} style={{ padding: 0 }}>
          <Card
            ref={{
              current: "[Circular]" as any,
            }}
            border="medium"
            elevated="small"
            className="card"
            style={{ width: "100%" }}
          >
            <ExperimentStatus experiment={experiment} />
          </Card>
        </Item>
        {experiment && isNotItEmptyOrNull(experiment?.result) && (
          <Item sm={12} md={12} lg={12} xl={12} xs={12}>
            <Card
              ref={{
                current: "[Circular]" as any,
              }}
              border="medium"
              elevated="small"
              className="card"
            >
              <ExperimentResults
                action={{
                  content: "Edit",
                  icon: <Edit2 />,
                  stateModal: setEnterResultsOpen,
                }}
                experiment={experiment}
              />
            </Card>
          </Item>
        )}
        <Item sm={12} md={12} lg={12} xl={12} xs={12} style={{ padding: 0 }}>
          <Card
            ref={{
              current: "[Circular]" as any,
            }}
            border="medium"
            elevated="small"
            className="card"
            style={{ width: "100%" }}
          >
            <ExperimentSchedule
              setIsEstimatedEndDateDialogOpen={setIsEstimatedEndDateDialogOpen}
              experiment={experiment}
            />
          </Card>
        </Item>
        <Item sm={12} md={12} lg={12} xl={12} xs={12} style={{ padding: 0 }}>
          <Card
            ref={{
              current: "[Circular]" as any,
            }}
            border="medium"
            elevated="small"
            className="card"
            style={{ width: "100%" }}
          >
            <BasicInformation experiment={experiment} />
          </Card>
        </Item>
        <Item sm={12} md={12} lg={12} xl={12} xs={12} style={{ padding: 0 }}>
          <Card
            ref={{
              current: "[Circular1]" as any,
            }}
            border="medium"
            elevated="small"
            className="card"
            style={{ width: "100%" }}
          >
            <Metrics experiment={experiment} />
          </Card>
        </Item>
        <Item sm={12} md={12} lg={12} xl={12} xs={12} style={{ padding: 0 }}>
          <Card
            ref={{
              current: "[Circular]" as any,
            }}
            border="medium"
            elevated="small"
            className="card"
            style={{ width: "100%" }}
          >
            <AudienceSelect experiment={experiment} />
          </Card>
        </Item>
        <Item sm={12} md={12} lg={12} xl={12} xs={12} style={{ padding: 0 }}>
          <Card
            ref={{
              current: "[Circular]" as any,
            }}
            border="medium"
            elevated="small"
            className="card"
            style={{ width: "100%" }}
          >
            <Variants experiment={experiment} />
          </Card>
        </Item>
      </Item>
    </Item>
  );
};

export default ExperimentDetails;
