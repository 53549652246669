import { Button, Dialog, Heading, Paragraph } from "@hexa-ui/components";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const CustomCreateNewAudienceDialog = ({
  openCreateNewAudienceDialog,
  setOpenCreateNewAudienceDialog,
}: CustomCreateNewAudienceDialogProps) => {
  const translateKey = [
    "create_and_update_experiment.audience.dialog.steps.step1",
    "create_and_update_experiment.audience.dialog.steps.step2",
    "create_and_update_experiment.audience.dialog.steps.step3",
    "create_and_update_experiment.audience.dialog.steps.step4",
    "create_and_update_experiment.audience.dialog.steps.step5",
    "create_and_update_experiment.audience.dialog.steps.step6",
  ];

  return (
    <>
      <Dialog.Root
        data-testid="create_new_audience-dialog"
        closeButton={false}
        actions={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              data-testid={`create_new_audience-close`}
              onClick={() => setOpenCreateNewAudienceDialog(false)}
              size="medium"
              style={{ marginRight: "0.9375rem" }}
              variant="secondary"
            >
              <FormattedMessage
                id={`create_and_update_experiment.audience.dialog.cancel`}
              />
            </Button>
            <Link
              style={{ textDecoration: "none" }}
              to="/audiences?origin=experimentationPlatform"
            >
              <Button
                data-testid={`create_new_audience-continue`}
                size="medium"
                variant="primary"
              >
                <FormattedMessage
                  id={`create_and_update_experiment.audience.dialog.continue`}
                />
              </Button>
            </Link>
          </div>
        }
        title={
          <Heading width="601px" size="H2">
            <FormattedMessage
              id={`create_and_update_experiment.audience.dialog.header`}
            />
          </Heading>
        }
        open={openCreateNewAudienceDialog}
      >
        <Paragraph style={{ width: "553px" }}>
          <FormattedMessage
            id={`create_and_update_experiment.audience.dialog.sub_header`}
          />
        </Paragraph>
        <Paragraph>
          <ol style={{ paddingLeft: "19px" }}>
            {translateKey.map((tKey) => {
              return (
                <li key={tKey}>
                  <FormattedMessage id={tKey} />
                </li>
              );
            })}
          </ol>
        </Paragraph>
      </Dialog.Root>
    </>
  );
};

type CustomCreateNewAudienceDialogProps = {
  openCreateNewAudienceDialog: boolean;
  setOpenCreateNewAudienceDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

export default CustomCreateNewAudienceDialog;
