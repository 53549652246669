import { IExperiment, IExperimentationVariation } from "@/types/experiment";
import { Divider, Grid, Heading, Paragraph } from "@hexa-ui/components";
import { FormattedMessage } from "react-intl";
import CardHeading from "../../CardHeading/CardHeading";
import EmptySection from "../EmptySection/EmptySection";
import { VARIANT_TYPE } from "./../../../pages/ExperimentMutation/utils";
import { IActionLink, VariationsTypes } from "./../../../types/common";

const Variants = ({
  experiment,
  action,
}: {
  experiment: IExperiment;
  action?: IActionLink;
}): JSX.Element => {
  const { Container, Item } = Grid;

  const getVariantType = (variant: IExperimentationVariation) => {
    if (variant?.configObject)
      return VariationsTypes.find(
        (type) => type.id === VARIANT_TYPE.APPLICATION_CODED
      ).name;
    return VariationsTypes.find(
      (type) => type.id === VARIANT_TYPE.PLATFORM_SETTING
    ).name;
  };

  return (
    <>
      <Item sm={12} style={{ display: "flex", flexDirection: "column" }}>
        <CardHeading
          heading={
            <Heading size="H3">
              <FormattedMessage id="experiment_details.variants.variants" />
            </Heading>
          }
          action={action}
        />
        {experiment?.variations?.length > 0 ? (
          <Item lg={12} md={12} sm={12} xl={12} xs={12} style={{ padding: 0 }}>
            <div
              style={{
                width: "100%",
              }}
              key={experiment.id}
            >
              <Container style={{ width: "100%", alignItems: "center" }}>
                <Item lg={2} md={2} sm={12} xl={2} xs={12}>
                  <div style={{ width: "100%" }}>
                    <div style={{ marginTop: 16, marginBottom: 16 }}>
                      <Heading
                        size="H5"
                        style={{ color: "rgba(20, 20, 20, 0.56)" }}
                      >
                        <FormattedMessage id="experiment_details.variants.control_version" />
                      </Heading>
                    </div>
                  </div>
                </Item>
                <Item lg={10} md={10} sm={12} xl={10} xs={12}>
                  <div style={{ width: "100%" }}>
                    <div style={{ marginTop: 16, marginBottom: 16 }}>
                      <Paragraph>
                        <FormattedMessage id="experiment_details.variants.control_version_text" />
                      </Paragraph>
                    </div>
                  </div>
                </Item>
                <Divider css={{ marginLeft: "8px" }} orientation="horizontal" />
              </Container>
              {experiment?.variations.map((item, index) => (
                <Container
                  style={{
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Item lg={2} md={2} sm={12} xl={2} xs={12}>
                    <div style={{ width: "100%" }}>
                      <div style={{ marginTop: 16, marginBottom: 16 }}>
                        <Heading
                          size="H5"
                          style={{ color: "rgba(20, 20, 20, 0.56)" }}
                        >
                          <FormattedMessage id="experiment_details.variants.variant" />{" "}
                          {String.fromCharCode(
                            "a".charCodeAt(0) + index
                          ).toLocaleUpperCase()}
                        </Heading>
                      </div>
                    </div>
                  </Item>
                  <Item lg={3} md={3} sm={12} xl={2} xs={12}>
                    <div style={{ width: "100%" }}>
                      <div style={{ marginTop: 16, marginBottom: 16 }}>
                        <Heading size="H5">
                          <FormattedMessage id="experiment_details.variants.variant_name" />
                        </Heading>
                        <Paragraph>{item.name}</Paragraph>
                      </div>
                    </div>
                  </Item>
                  <Item lg={4} md={4} sm={12} xl={4} xs={12}>
                    <div style={{ width: "100%" }}>
                      <div style={{ marginTop: 16, marginBottom: 16 }}>
                        <Heading size="H5">
                          <FormattedMessage id="experiment_details.variants.description" />
                        </Heading>
                        <Paragraph>{item.description}</Paragraph>
                      </div>
                    </div>
                  </Item>
                  <Item lg={3} md={3} sm={12} xl={2} xs={12}>
                    <div style={{ width: "100%" }}>
                      <div style={{ marginTop: 16, marginBottom: 16 }}>
                        <Heading size="H5">
                          <FormattedMessage id="experiment_details.variants.type" />{" "}
                        </Heading>
                        <Paragraph>{getVariantType(item)}</Paragraph>
                      </div>
                    </div>
                  </Item>
                  {index !== experiment.variations.length - 1 && (
                    <Divider
                      css={{ marginLeft: "8px" }}
                      orientation="horizontal"
                    />
                  )}
                </Container>
              ))}
            </div>
          </Item>
        ) : (
          <EmptySection
            header={
              <FormattedMessage id="experiment_details.variants.variants" />
            }
            message={<FormattedMessage id="empty_session.variants_message" />}
          />
        )}
      </Item>
    </>
  );
};

export default Variants;
