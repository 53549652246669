import { useQuery } from "react-query";
import { getGuardrails } from "../../../services/guardrails/guardrails";

export const useGetGuardrails = () => {
  const query = useQuery({
    queryFn: () => getGuardrails(),
    queryKey: ["guardrails"],
    retry: 5,
    refetchOnWindowFocus: false,
  });
  return query;
};
