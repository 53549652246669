import { IExperimentRequest } from "@/types/experiment";
import { useMutation } from "react-query";
import { updateExpriment } from "../../../services/experiment/experimentService";

export const useUpdateExperiment = (experimentId: string) => {
  const mutation = useMutation({
    mutationFn: (formData: IExperimentRequest) => updateExpriment(formData),
    mutationKey: ["updateExperiment", experimentId],
  });
  return mutation;
};
