import { useSidebar } from "admin-portal-shared-services";
import { useIntl } from "react-intl";

import { envs } from "../config/env";
import { HomeIcon, UsersIcon } from "./sidebarUtils";

const useAppBar = (): void => {
  const { formatMessage } = useIntl();

  useSidebar({
    items: [
      {
        id: "0",
        title: formatMessage({ id: "List Experiment" }),
        icon: HomeIcon,
        path: `/${envs.URL_BASE}`,
      },
      {
        id: "1",
        title: formatMessage({ id: "Audience Editor" }),
        icon: UsersIcon,
        path: `/audiences?origin=experimentationPlatform`,
      },
    ],
    utils: [],
  });
};

export default useAppBar;
