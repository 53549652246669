import { Button, Dialog, Heading, Paragraph } from "@hexa-ui/components";
import { createContext, useContext, useState } from "react";

type DialogButtonAction = {
  action: () => void;
  dialogActionText: string;
  dialogActionButtonType: string;
};

const initialState = {
  openDialog: (
    _headerText: string,
    _contentText: string,
    _confirmActionButton: DialogButtonAction,
    _cancelActionButton: DialogButtonAction
  ) => {},
  closeDialog: () => {},
};

const DialogContext = createContext(initialState);

export const useDialog = () => useContext(DialogContext);

const DialogProvider = ({ children }) => {
  const [isOpen, setOpenDialog] = useState(false);
  const [headerText, setHeaderText] = useState("");
  const [contentText, setContentText] = useState("");
  const [confirmActionButton, setHandleConfirmAction] = useState({
    action: () => {},
    dialogActionText: "",
    dialogActionButtonType: "",
  });
  const [cancelActionButton, setHandleCancelAction] = useState({
    action: () => {},
    dialogActionText: "",
    dialogActionButtonType: "",
  });

  const openDialog = (
    headerText: string,
    contentText: string,
    confirmActionButton: DialogButtonAction,
    cancelActionButton: DialogButtonAction
  ) => {
    setHeaderText(headerText);
    setContentText(contentText);
    setHandleConfirmAction(confirmActionButton);
    setHandleCancelAction(cancelActionButton);
    setOpenDialog(true);
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirm = () => {
    confirmActionButton?.action();
  };

  const handleCancel = () => {
    if (cancelActionButton.action) {
      cancelActionButton.action();
    } else {
      closeDialog();
    }
  };

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog }}>
      {children}

      {isOpen && (
        <Dialog.Root
          contentCss={{ width: "448px" }}
          closeButton={false}
          actions={
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "448px",
              }}
            >
              <Button
                data-testid={`custom-dialog-continue-confirm`}
                onClick={() => handleConfirm()}
                size="medium"
                style={{ marginRight: "0.9375rem" }}
                variant={confirmActionButton?.dialogActionButtonType as any}
              >
                {confirmActionButton?.dialogActionText}
              </Button>

              <Button
                onClick={() => handleCancel()}
                data-testid={`custom-dialog-close`}
                size="medium"
                variant={cancelActionButton?.dialogActionButtonType as any}
              >
                {cancelActionButton?.dialogActionText}
              </Button>
            </div>
          }
          title={
            <Heading width={"448px"} size="H2">
              {headerText}
            </Heading>
          }
          open={isOpen}
        >
          <Paragraph>{contentText}</Paragraph>
        </Dialog.Root>
      )}
    </DialogContext.Provider>
  );
};

export default DialogProvider;
