import { AudienceObject } from "@dlb-shared-business-components/audience";
import { useState } from "react";
import { IExperimentAudience } from "../../types/experiment";

type SelectedAudienceProps = {
  audience: IExperimentAudience | undefined;
};

const useSelectedAudience = ({ audience }: SelectedAudienceProps) => {
  const [selectedAudience, setSelectedAudience] = useState<
    IExperimentAudience | undefined
  >(audience);

  const handleSelectedAudience = (currentAudience?: AudienceObject) => {
    setSelectedAudience({
      externalId: currentAudience?.audienceId || "",
      name: currentAudience?.audienceName || "",
    });
  };

  return {
    selectedAudience,
    handleSelectedAudience,
  };
};

export default useSelectedAudience;
