import { IExperimentRequest } from "@/types/experiment";
import { useMutation } from "react-query";
import { createExperiment } from "../../../services/experiment/experimentService";

export const useCreateExperiment = () => {
  const mutation = useMutation({
    mutationFn: (formData: IExperimentRequest) => createExperiment(formData),
    mutationKey: ["createExperiment"],
  });
  return mutation;
};
