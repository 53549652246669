import { Heading } from "@hexa-ui/components";
import { FormattedMessage } from "react-intl";
type CustomHeadingProps = {
  customStyle?: any;
  transllatedTitleKey?: string;
  title?: string;
};

const CustomHeading = ({
  transllatedTitleKey,
  title,
  customStyle,
}: CustomHeadingProps) => {
  return (
    <Heading size="H2" css={customStyle}>
      {transllatedTitleKey ? (
        <FormattedMessage id={transllatedTitleKey} />
      ) : (
        title
      )}
    </Heading>
  );
};
export default CustomHeading;
