import {
  Alert,
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  Heading,
  IconButton,
  Input,
  Paragraph,
  Select,
  TextLink,
} from "@hexa-ui/components";
import { Plus, Trash2, X } from "@hexa-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import JsonInputField from "../../../components/JsonInputField/JsonInputField";
import PocIdsDialog from "../../../components/PocIdsDialog/PocIdsDialog";
import { setExperimentMutation } from "../../../store/ducks/ExperimentMutation/ExperimentMutationDuck";
import { useExperimentMutation } from "../../../store/hooks/ExperimentMutation/ExperimentMutationHook";
import {
  STEP3_VARIATIONS_ALLOW_LIST_MAX_NUMBER,
  STEP3_VARIATIONS_MAX_NUMBER,
  VariationsSettingOptions,
  VariationsTypes,
} from "../../../types/common";
import {
  ExperimentInputErrors,
  IFormVariation,
} from "../../../types/experiment";
import {
  VARIANT_TYPE,
  VARIANT_TYPE_SETTINGS,
  handleError,
  handleStep3FormFields,
} from "./../../../pages/ExperimentMutation/utils";

type Props = {
  inputErrors: {
    variant_a: string[];
    variant_b: string[];
    variant_c: string[];
    variant_d: string[];
    variant_e: string[];
    variant_f: string[];
  };
  setinputFormError: React.Dispatch<
    React.SetStateAction<ExperimentInputErrors>
  >;
};

const Step3 = ({ inputErrors, setinputFormError }: Props): JSX.Element => {
  const { Container, Item } = Grid;

  const pocIdMainInputId = useRef(null);
  const [pocId, setPocId] = useState<string>("");

  const {
    experimentMutation: { variations: variants },
  } = useExperimentMutation();
  const dispatch = useDispatch();

  const intl = useIntl();

  const defaultVariant: IFormVariation = {
    // Edited to redux
    id: "variant_id",
    name: "",
    description: "",
    // configObject: "",
    type: "application-coded",
    settingOptions: [],
    json: "",
    viewers: [],
  };

  const [openDialog, setOpenDialog] = useState<{
    status: boolean;
    variantId: string;
  }>({ status: false, variantId: "" });
  const [viewer, setViewer] = useState<string>("");
  const [isDialogInputValid, setIsDialogInputValid] = useState<any>({
    status: true,
    message: "",
  });

  const handleFieldsBasedOnSelect = (
    inputName: string,
    value: string,
    variantId: string
  ) => {
    const variant = variants.find((variant) => variant.id === variantId);
    const newVariant = handleStep3FormFields({
      ...variant,
      [inputName]: value,
    });

    handleOnChangeVariant(inputName, value, variantId, newVariant);
  };

  const handleOnChangeVariant = (
    inputName: string,
    value: string,
    variantId: string,
    newVariant?: IFormVariation
  ) => {
    const errors = handleError(inputName, value, inputErrors[variantId]);
    const index = variants.findIndex((variant) => variant.id === variantId);
    dispatch(
      setExperimentMutation({
        variations: [
          ...variants.map((variant) => {
            if (variant.id === variantId) {
              return (
                newVariant || {
                  ...variant,
                  [inputName]: value,
                }
              );
            }
            return variant;
          }),
        ],
      })
    );
    errors &&
      setinputFormError({
        step1: [],
        step2: [],
        step3: { ...inputErrors, [variantId]: errors },
      });
  };

  const ids = ["a", "b", "c", "d", "e", "f"];
  const getLastId = (list) => {
    if (list.length === 0) return ids[0];

    return ids[list.length];
  };

  useEffect(() => {
    if (variants?.length === 0) {
      handleVariantCreation();
    }
  }, [variants]);

  const handleVariantCreation = () => {
    const newVariant = { ...defaultVariant };
    newVariant.id = `variant_${getLastId(variants)}`;
    dispatch(
      setExperimentMutation({
        variations: [...variants, newVariant],
      })
    );
  };

  const handleDeleteVariant = (variantId: string) => {
    const filteredVariations = variants.filter(
      (variant) => variant.id !== variantId
    );
    dispatch(
      setExperimentMutation({
        variations: filteredVariations.map((variant, index) => {
          return {
            ...variant,
            id: `variant_${ids[index]}`,
          };
        }),
      })
    );
  };

  const handleAddViewer = () => {
    setOpenDialog({ status: false, variantId: "" });
  };

  const handleDeleteViewer = (variantId: string, viewerId: string) => {
    const index = variants.findIndex((variant) => variant.id === variantId);
    const viewers = variants[index].viewers.filter(
      (viewer) => viewer !== viewerId
    );
    dispatch(
      setExperimentMutation({
        variations: [
          ...variants.map((variant) => {
            if (variant.id === variantId) {
              return {
                ...variant,
                viewers: viewers,
              };
            }
            return variant;
          }),
        ],
      })
    );
  };

  const getCopyTestContent = (variant: IFormVariation) => {
    return (
      variant?.settingOptions?.some(
        (option) => option === VARIANT_TYPE_SETTINGS.COPY_TEST
      ) && (
        <Container style={{ width: "100%" }}>
          <Item
            lg={12}
            md={12}
            sm={12}
            xl={12}
            xs={12}
            style={{ marginTop: 30 }}
          >
            <Paragraph size="basis" weight="semibold">
              Copy test
            </Paragraph>{" "}
          </Item>
          <Item lg={6} md={6} sm={12} xl={6} xs={12} style={{ marginTop: 30 }}>
            <div style={{ width: "100%" }}>
              <Input
                label="Copy key"
                placeholder="Enter copy key"
                hasError={errorVerify(variant?.id, "androidStringKey")}
                errorText={`${intl.formatMessage({ id: "create_and_update_experiment.error_messages.step3.copy_key" })}`}
                width={"100%"}
                value={variant?.androidStringKey}
                data-testid={`input-copy-key-${variant?.id}`}
                name="androidStringKey"
                onChange={(e: any) =>
                  handleOnChangeVariant(
                    "androidStringKey",
                    e.target.value,
                    variant?.id
                  )
                }
              />
            </div>
          </Item>

          <Item lg={6} md={6} sm={12} xl={6} xs={12} style={{ marginTop: 30 }}>
            <div style={{ width: "100%" }}>
              <Input
                label="Copy"
                name="stringValue"
                placeholder="Enter copy"
                hasError={errorVerify(variant?.id, "stringValue")}
                errorText={`${intl.formatMessage({ id: "create_and_update_experiment.error_messages.step3.copy" })}`}
                value={variant?.stringValue}
                data-testid={`input-copy-${variant?.id}`}
                width={"100%"}
                onChange={(e: any) =>
                  handleOnChangeVariant(
                    "stringValue",
                    e.target.value,
                    variant?.id
                  )
                }
              />
            </div>
          </Item>
        </Container>
      )
    );
  };

  const getServerDrivenUiContent = (variant: IFormVariation) =>
    variant?.settingOptions?.some(
      (option) => option === VARIANT_TYPE_SETTINGS.SERVER_DRIVEN_UI
    ) && (
      <Container style={{ width: "100%" }}>
        <Item lg={12} md={12} sm={12} xl={12} xs={12} style={{ marginTop: 30 }}>
          <Paragraph size="basis" weight="semibold">
            SDUI (Server driven UI)
          </Paragraph>{" "}
        </Item>
        <Item lg={6} md={6} sm={12} xl={6} xs={12} style={{ marginTop: 30 }}>
          <div style={{ width: "100%" }}>
            <Input
              label="Container ID"
              placeholder="Enter label key"
              hasError={errorVerify(variant?.id, "containerId")}
              errorText={`${intl.formatMessage({ id: "create_and_update_experiment.error_messages.step3.container_id" })}`}
              width={"100%"}
              data-testid={`input-container-id-${variant?.id}`}
              value={variant?.containerId}
              name="containerId"
              onChange={(e: any) =>
                handleOnChangeVariant(
                  "containerId",
                  e.target.value,
                  variant?.id
                )
              }
            />
          </div>
        </Item>
      </Container>
    );

  const errorVerify = (id: string, fieldName?: string) => {
    if (inputErrors) {
      const hasErrors = inputErrors[id]?.includes(fieldName);
      return hasErrors;
    }
  };

  const handleGoBack = () => {
    setIsDialogInputValid({
      status: true,
      message: "",
    });
    setOpenDialog({ status: false, variantId: "" });
    setPocId("");
  };

  return (
    <>
      <Container
        className="w-full"
        data-testid="step-3"
        style={{ width: "100%" }}
      >
        <Item style={{ display: "flex", flexDirection: "column" }} sm={12}>
          <Item
            lg={12}
            md={12}
            sm={12}
            xl={12}
            xs={12}
            style={{
              marginLeft: 0,
              marginRight: 0,
              marginBottom: 15,
              marginTop: 30,
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <Alert
              style={{ width: "100%" }}
              message={`${intl.formatMessage({ id: "create_and_update_experiment.labels.step3.alert.title" })}`}
            />
          </Item>

          <Card
            ref={{
              current: "[Circular]" as any,
            }}
            border="medium"
            elevated="small"
            style={{
              marginBottom: 30,
              padding: 24,
              width: "100%",
            }}
          >
            <Item
              lg={12}
              md={12}
              sm={12}
              xl={12}
              xs={12}
              style={{
                marginBottom: 10,
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <Heading size="H3" style={{ marginBottom: "10" }}>
                <FormattedMessage id="create_and_update_experiment.labels.step3.control_version.title" />
              </Heading>
            </Item>
            <span>
              <FormattedMessage id="create_and_update_experiment.labels.step3.control_version.paragraph" />
            </span>
          </Card>

          <Card
            ref={{
              current: "[Circular]" as any,
            }}
            border="medium"
            elevated="small"
            style={{
              paddingTop: 24,
              paddingLeft: 8,
              paddingRight: 8,
              paddingBottom: 24,
              width: "100%",
            }}
          >
            <Container
              style={{
                rowGap: "1rem",
                width: "100%",
              }}
            >
              <Item lg={12} md={12} sm={12} xl={12} xs={12}>
                <Heading
                  size="H3"
                  style={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }}
                >
                  <FormattedMessage id="create_and_update_experiment.labels.step3.title" />
                </Heading>
              </Item>

              {variants?.length > 0 &&
                variants?.map((variant, index) => (
                  <Item
                    lg={12}
                    md={12}
                    sm={12}
                    xl={12}
                    xs={12}
                    key={variant?.id}
                  >
                    <Container style={{ width: "100%" }}>
                      <Item
                        lg={12}
                        md={12}
                        sm={12}
                        xl={12}
                        xs={12}
                        style={{
                          marginBottom: 20,
                          marginTop: 10,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Heading size="H4">
                          Variant{" "}
                          {String.fromCharCode(
                            "a".charCodeAt(0) + index
                          ).toLocaleUpperCase()}
                        </Heading>
                        {variants?.length > 1 && (
                          <IconButton
                            icon={Trash2}
                            data-testid={`icon-delete-variant-${variant?.id}`}
                            variant="tertiary"
                            type="button"
                            onClick={() => handleDeleteVariant(variant?.id)}
                          />
                        )}
                      </Item>
                      <Item lg={6} md={6} sm={12} xl={6} xs={12}>
                        <div style={{ width: "100%" }}>
                          <Input
                            label="Variant name"
                            placeholder="Enter variant name"
                            value={variant?.name}
                            hasError={errorVerify(variant?.id, "name")}
                            errorText={`${intl.formatMessage({ id: "create_and_update_experiment.error_messages.step3.variant_name" })}`}
                            onChange={(e: any) =>
                              handleOnChangeVariant(
                                "name",
                                e.target.value,
                                variant?.id
                              )
                            }
                            data-testid={`input-variant-name-${variant?.id}`}
                            width={"100%"}
                            name="name"
                          />
                        </div>
                      </Item>
                      <Item lg={6} md={6} sm={12} xl={6} xs={12}>
                        <div style={{ width: "100%" }}>
                          <Input
                            label="Variant description"
                            placeholder="Enter variant description"
                            width={"100%"}
                            value={variant?.description}
                            hasError={errorVerify(variant?.id, "description")}
                            errorText={`${intl.formatMessage({ id: "create_and_update_experiment.error_messages.step3.variant_description" })}`}
                            data-testid={`input-variant-description-${variant?.id}`}
                            name="description"
                            onChange={(e: any) =>
                              handleOnChangeVariant(
                                "description",
                                e.target.value,
                                variant?.id
                              )
                            }
                          />
                        </div>
                      </Item>
                      <Item
                        lg={2}
                        md={2}
                        sm={12}
                        xl={2}
                        xs={12}
                        style={{ marginTop: 30 }}
                      >
                        <div style={{ width: "100%" }}>
                          <Select.Root
                            id="input-type"
                            label="Type"
                            defaultValue={"Application coded"}
                            value={
                              variant?.type
                              // variant &&
                              // variantType &&
                              // variantType[variant?.id]?.type
                            }
                            onChange={
                              (e: any) =>
                                handleFieldsBasedOnSelect(
                                  "type",
                                  e,
                                  variant?.id
                                ) // Here
                            }
                            data-testid={`select-type-${variant?.id}`}
                            name="type"
                          >
                            {VariationsTypes.map((item) => (
                              <Select.Option
                                key={item.id}
                                value={item.id}
                                data-testid={`type-${item.id}`}
                              >
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select.Root>
                        </div>
                      </Item>
                      <Item
                        lg={5}
                        md={5}
                        sm={12}
                        xl={5}
                        xs={12}
                        style={{ marginTop: 30 }}
                      >
                        <div style={{ width: "100%" }}>
                          <Select.Root
                            label="Setting options"
                            disabled={
                              variant?.type === VARIANT_TYPE.APPLICATION_CODED
                            }
                            onChange={(e: any) =>
                              handleFieldsBasedOnSelect(
                                "settingOptions",
                                e,
                                variant?.id
                              )
                            }
                            placeholder="Select"
                            multiple
                            shape="square"
                            name="settingOptions"
                            value={
                              variant?.settingOptions
                              // variantType &&
                              // variantType[variant?.id]?.settingOptions
                            }
                            error={
                              errorVerify(variant?.id, "settingOptions")
                                ? intl.formatMessage({
                                    id: "create_and_update_experiment.error_messages.step3.setting_options",
                                  })
                                : ""
                            }
                            data-testid={`setting-options-${variant?.id}`}
                          >
                            {VariationsSettingOptions.map((item) => (
                              <Select.Option
                                key={item.id}
                                value={item.id}
                                data-testid={`${item.id}-${variant?.id}`}
                              >
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select.Root>
                        </div>
                      </Item>

                      {variant?.type === VARIANT_TYPE.PLATFORM_SETTING && (
                        <Container
                          style={{ width: "100%" }}
                          key={`variant-${variant?.id}-type-${variant?.id}`}
                        >
                          {getCopyTestContent(variant)}
                          {getServerDrivenUiContent(variant)}
                        </Container>
                      )}
                      {(variant?.type === VARIANT_TYPE.APPLICATION_CODED ||
                        variant?.settingOptions?.includes(
                          VARIANT_TYPE_SETTINGS.SERVER_DRIVEN_UI
                        )) && (
                        <Item
                          lg={12}
                          md={12}
                          sm={12}
                          xl={12}
                          xs={12}
                          style={{ marginTop: 30 }}
                        >
                          <JsonInputField
                            jsonValue={variant?.json}
                            name="json"
                            inputErrors={errorVerify(variant?.id, "json")}
                            id={`textarea-json-${variant?.id}`}
                            label="JSON"
                            width={"100%"}
                            variant={variant}
                            changeAction={handleOnChangeVariant}
                          />
                        </Item>
                      )}

                      <Item
                        lg={12}
                        md={12}
                        sm={12}
                        xl={12}
                        xs={12}
                        style={{ marginTop: 30 }}
                      >
                        <div style={{ width: "100%" }}>
                          <Paragraph size="small" style={{ fontWeight: 600 }}>
                            <FormattedMessage id="create_and_update_experiment.labels.step3.variants.section_allow_list.title" />{" "}
                            <span
                              style={{
                                fontSize: "var(--fontSizes-1)",
                                fontWeight: "var(--fontWeights-normal)",
                                color: "var(--colors-interfaceLabelSecondary)",
                                paddingBottom: "var(--space-1)",
                              }}
                            >
                              (
                              <FormattedMessage id="create_and_update_experiment.labels.step3.variants.section_allow_list.optional" />
                              )
                            </span>
                          </Paragraph>
                          <div
                            style={{
                              marginTop: 10,
                              display: "flex",
                              alignItems: "center",
                            }}
                            data-testid="div-select-viewers"
                          >
                            {variant?.viewers?.map((viewer, index) => (
                              <div key={`${viewer}-${index}`}>
                                <Chip.Root
                                  disabled={true}
                                  style={{ marginRight: 10 }}
                                >
                                  <Chip.Label
                                    data-testid={`viewer-${variant?.id}-${viewer}`}
                                  >
                                    {`POC: ${viewer}`}
                                  </Chip.Label>
                                  <IconButton
                                    style={{ marginLeft: "5px" }}
                                    icon={X}
                                    data-testid={`delete-chip-${variant?.id}-${viewer.replace(" ", "-")}`}
                                    variant="tertiary"
                                    type="button"
                                    size="tiny"
                                    onClick={() =>
                                      handleDeleteViewer(variant?.id, viewer)
                                    }
                                  />
                                </Chip.Root>
                              </div>
                            ))}

                            <div>
                              <TextLink
                                style={{
                                  pointerEvents:
                                    variant?.viewers &&
                                    variant?.viewers?.length >=
                                      STEP3_VARIATIONS_ALLOW_LIST_MAX_NUMBER
                                      ? ("none" as React.CSSProperties["pointerEvents"])
                                      : ("auto" as React.CSSProperties["pointerEvents"]),
                                }}
                                data-testid="link-add-viewer"
                                onClick={() =>
                                  setOpenDialog({
                                    status: true,
                                    variantId: variant?.id,
                                  })
                                }
                              >
                                +{" "}
                                <FormattedMessage id="create_and_update_experiment.buttons.step3.add_viewer" />
                              </TextLink>
                            </div>
                            <PocIdsDialog
                              openDialog={openDialog}
                              handleAddViewer={handleAddViewer}
                              handleGoBack={handleGoBack}
                              // listOfPocIds={listOfPocIds}
                              // setListOfPocIds={setListOfPocIds}
                              variantId={openDialog?.variantId}
                            />
                          </div>
                        </div>
                      </Item>
                      <Item
                        lg={12}
                        md={12}
                        sm={12}
                        xl={12}
                        xs={12}
                        style={{ marginTop: 20, marginBottom: 20 }}
                      >
                        <Divider orientation="horizontal" />
                      </Item>

                      <Item
                        lg={12}
                        md={12}
                        sm={12}
                        xl={12}
                        xs={12}
                        style={{
                          marginTop: 20,
                          marginBottom: 20,
                          justifyContent: "end",
                        }}
                      ></Item>
                    </Container>
                  </Item>
                ))}
              <Item
                lg={12}
                md={12}
                sm={12}
                xl={12}
                xs={12}
                style={{ justifyContent: "end" }}
              >
                <Button
                  icon={Plus}
                  data-testid="button-create-variant"
                  size="medium"
                  type="button"
                  leading
                  variant="secondary"
                  onClick={() => handleVariantCreation()}
                  disabled={variants?.length >= STEP3_VARIATIONS_MAX_NUMBER}
                >
                  <FormattedMessage id="create_and_update_experiment.buttons.step3.create_variant" />
                </Button>
              </Item>
            </Container>
          </Card>
        </Item>
      </Container>
    </>
  );
};

export default Step3;
