import * as Grid from "@admin-portal-shared-components/grid";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export default function PageWrapper({ children }: Props) {
  return (
    <Grid.Container
      type="fluid"
      style={{
        width: "1304px",
        margin: "0 auto",
        fontFamily: "Work Sans",
      }}
      sidebar
    >
      {children}
    </Grid.Container>
  );
}
