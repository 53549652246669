import {
  Card,
  Chip,
  Grid,
  Heading,
  Input,
  Paragraph,
  Select,
  TextLink,
} from "@hexa-ui/components";
import { HelpCircle } from "@hexa-ui/icons";
import { Fragment, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useMetrics } from "../../../context/MetricsContext/metricProvider";
import useSelectedAudience from "../../../hooks/useSelectedAudience/useSelectedAudience";
import { getMetrics } from "../../../services/metrics/metrics";
import { setExperimentMutation } from "../../../store/ducks/ExperimentMutation/ExperimentMutationDuck";
import { useExperimentMutation } from "../../../store/hooks/ExperimentMutation/ExperimentMutationHook";
import { Countries, ExperimentInputErrors } from "../../../types/experiment";
import CustomNameGuidelineDialog from "./../../../components/CustomNameGuidelineDialog/CustomNameGuidelineDialog";
import { getAvailableCountries } from "./../../../pages/ExperimentMutation/constants";
import { Guardrails } from "./../../../types/guardrails";

type Props = {
  guardrails: Guardrails;
  inputError: string[];
  setinputFormError: React.Dispatch<
    React.SetStateAction<ExperimentInputErrors>
  >;
};

const Step1 = ({
  guardrails,
  inputError,
  setinputFormError,
}: Props): JSX.Element => {
  const { Container, Item } = Grid;
  const intl = useIntl();
  const [nameGuidelineOpenDialog, setNameguidelineOpenDialog] =
    useState<boolean>(false);

  const { data: metrics } = useQuery({
    queryFn: () => getMetrics(),
    queryKey: ["metrics"],
    retry: 5,
  });

  const { handlePrimaryMetrics, handleSecondaryMetrics } = useMetrics();

  const dispatch = useDispatch();

  const { experimentMutation } = useExperimentMutation();

  const [primaryMetric, setPrimaryMetric] = useState(
    metrics?.primaryMetrics?.length > 0 ? metrics?.primaryMetrics : []
  );

  const [secondaryMetrics, setSecondaryMetrics] = useState(
    metrics?.secondaryMetrics?.length > 0 ? metrics?.secondaryMetrics : []
  );

  const { handleSelectedAudience } = useSelectedAudience({
    audience: experimentMutation.audiences.audiences,
  });

  const AvailableCountries: Countries = getAvailableCountries();

  const mountBasicInformationState = (inputName: string, value) => {
    if (
      inputName === "country" &&
      experimentMutation.basicInformation.country !== value
    ) {
      handleSelectedAudience();

      return {
        basicInformation: {
          ...experimentMutation.basicInformation,
          [inputName]: value,
        },
        audiences: {
          ...experimentMutation.audiences,
          audiences: {
            externalId: "",
            name: "",
          },
        },
      };
    }
    return {
      basicInformation: {
        ...experimentMutation.basicInformation,
        [inputName]: value,
      },
    };
  };

  const handleOnChangeForm = (inputName, value) => {
    const errors = inputError.filter((item) => item !== inputName);
    setinputFormError({
      step1: errors,
      step2: [],
      step3: {
        errors: false,
        variant_a: [],
        variant_b: [],
        variant_c: [],
        variant_d: [],
        variant_e: [],
        variant_f: [],
      },
    });
    dispatch(
      setExperimentMutation(mountBasicInformationState(inputName, value))
    );
  };

  useEffect(() => {
    if (metrics) {
      setPrimaryMetric(
        metrics?.primaryMetrics?.length > 0 ? metrics?.primaryMetrics : []
      );
      setSecondaryMetrics(
        metrics?.secondaryMetrics?.length > 0 ? metrics?.secondaryMetrics : []
      );

      handlePrimaryMetrics(metrics?.primaryMetrics);
      handleSecondaryMetrics(metrics?.secondaryMetrics);
    }
  }, [metrics]);

  return (
    <Fragment>
      {nameGuidelineOpenDialog && (
        <CustomNameGuidelineDialog
          openDialog={nameGuidelineOpenDialog}
          setOpened={setNameguidelineOpenDialog}
          dataTestid="create__name-guideline-dialog"
        />
      )}

      <Item
        sm={12}
        md={12}
        lg={12}
        xl={12}
        xs={12}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        data-testid="step-1"
      >
        {/* {dialog} */}
        <Item sm={12}>
          <Card
            ref={{
              current: "[Circular]" as any,
            }}
            border="medium"
            elevated="small"
            style={{
              marginTop: 50,
              padding: 20,
              width: "100%",
            }}
          >
            <Container
              style={{
                rowGap: "1rem",
                width: "100%",
              }}
            >
              <Item lg={12} md={12} sm={12} xl={12} xs={12}>
                <Heading size="H3">
                  <FormattedMessage id="create_and_update_experiment.page_title_section.step1.experiment_details" />
                </Heading>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "auto",
                  }}
                >
                  <HelpCircle
                    style={{
                      color: "rgb(3,99,196)",
                      marginRight: "5px",
                    }}
                  />
                  <TextLink
                    size="small"
                    hasUnderline={false}
                    style={{ marginTop: 4, fontWeight: "500" }}
                    data-testid="read-about-them-link-name"
                    onClick={() => setNameguidelineOpenDialog(true)}
                  >
                    <FormattedMessage id="create_and_update_experiment.labels.step1.basic_information.link" />
                  </TextLink>
                </span>
              </Item>
              <Item lg={6} md={6} sm={12} xl={6} xs={12}>
                <div style={{ width: "100%" }}>
                  <div style={{ marginTop: 8 }}>
                    <Input
                      characterCounter
                      maxLength={100}
                      id="input-experiment-name"
                      data-testid="input-experiment-name"
                      hint={`${intl.formatMessage({ id: "create_and_update_experiment.hints.experiment_name" })}`}
                      label={`${intl.formatMessage({ id: "create_and_update_experiment.labels.step1.experiment_name" })}`}
                      placeholder={`${intl.formatMessage({ id: "create_and_update_experiment.placeholder.step1.experiment_name" })}`}
                      width={"100%"}
                      name="name"
                      hasError={inputError.includes("name")}
                      errorText={intl.formatMessage({
                        id: "create_and_update_experiment.error_messages.experiment_name",
                      })}
                      value={experimentMutation.basicInformation.name}
                      onChange={(e: any) =>
                        handleOnChangeForm("name", e.target.value)
                      }
                    />
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <Input
                      characterCounter
                      maxLength={100}
                      id="input-experiment-hypothesis"
                      data-testid="input-experiment-hypothesis"
                      label={`${intl.formatMessage({ id: "create_and_update_experiment.labels.step1.experiment_hypothesis" })}`}
                      placeholder={`${intl.formatMessage({ id: "create_and_update_experiment.placeholder.step1.experiment_hypothesis" })}`}
                      width={"100%"}
                      name="hypothesis"
                      hasError={inputError.includes("hypothesis")}
                      errorText={intl.formatMessage({
                        id: "create_and_update_experiment.error_messages.hypothesis",
                      })}
                      value={experimentMutation.basicInformation.hypothesis}
                      onChange={(e: any) =>
                        handleOnChangeForm("hypothesis", e.target.value)
                      }
                    />
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <Select.Root
                      error={
                        inputError.includes("country")
                          ? intl.formatMessage({
                              id: "create_and_update_experiment.error_messages.country",
                            })
                          : ""
                      }
                      label={`${intl.formatMessage({ id: "create_and_update_experiment.labels.step1.country" })}`}
                      onChange={(e: any) => handleOnChangeForm("country", e)}
                      placeholder="Select"
                      shape="square"
                      name="country"
                      value={
                        AvailableCountries[
                          experimentMutation.basicInformation.country
                        ]?.code ?? ""
                      }
                      data-testid="input-country"
                    >
                      {Object.keys(AvailableCountries).map((item) => (
                        <Select.Option
                          key={`${AvailableCountries[item].code}`}
                          value={AvailableCountries[item].code}
                          data-testid={`country-${AvailableCountries[item].code}`}
                        >
                          {AvailableCountries[item].label}
                        </Select.Option>
                      ))}
                    </Select.Root>
                  </div>
                </div>
              </Item>
              <Item lg={6} md={6} sm={12} xl={6} xs={12}>
                <div style={{ width: "100%" }}>
                  <div style={{ marginTop: 8, width: "100%" }}>
                    <Input
                      characterCounter
                      maxLength={100}
                      id="input-experiment-description"
                      data-testid="input-experiment-description"
                      label={`${intl.formatMessage({ id: "create_and_update_experiment.labels.step1.experiment_description" })}`}
                      hint={`${intl.formatMessage({ id: "create_and_update_experiment.hints.experiment_description" })}`}
                      placeholder={`${intl.formatMessage({ id: "create_and_update_experiment.placeholder.step1.experiment_description" })}`}
                      width={"100%"}
                      name="description"
                      hasError={inputError.includes("description")}
                      errorText={intl.formatMessage({
                        id: "create_and_update_experiment.error_messages.description",
                      })}
                      value={experimentMutation.basicInformation?.description}
                      onChange={(e: any) =>
                        handleOnChangeForm("description", e.target.value)
                      }
                    />
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <Input
                      id="input-confluence-documentation-link"
                      data-testid="input-confluence-documentation-link"
                      label={`${intl.formatMessage({ id: "create_and_update_experiment.labels.step1.confluence_documentation_link" })}`}
                      placeholder={`${intl.formatMessage({ id: "create_and_update_experiment.placeholder.step1.confluence_documentation_link" })}`}
                      width={"100%"}
                      name="documentationLink"
                      hasError={inputError.includes("documentationLink")}
                      errorText={intl.formatMessage({
                        id: "create_and_update_experiment.error_messages.documentation_link",
                      })}
                      value={
                        experimentMutation.basicInformation?.documentationLink
                      }
                      onChange={(e: any) =>
                        handleOnChangeForm("documentationLink", e.target.value)
                      }
                    />
                  </div>
                </div>
              </Item>
            </Container>
          </Card>
        </Item>
        <Item lg={6} md={6} sm={12} xl={6} xs={12}>
          <Card
            ref={{
              current: "[Circular]" as any,
            }}
            border="medium"
            elevated="small"
            style={{
              marginTop: 50,
              padding: 20,
              width: "100%",
            }}
          >
            <Container
              style={{
                rowGap: "1rem",
                width: "100%",
              }}
            >
              <Item
                lg={12}
                md={12}
                sm={12}
                xl={12}
                xs={12}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Heading size="H3">
                  <FormattedMessage id="create_and_update_experiment.page_title_section.step1.metrics" />
                </Heading>
                <span
                  style={{
                    fontWeight: "400",
                    fontSize: "12px",
                    color: "rgba(20, 20, 20, 0.56)",
                  }}
                >
                  <FormattedMessage id="create_and_update_experiment.hints.experiment_metrics" />
                </span>
              </Item>
              <Item lg={6} md={6} sm={12} xl={6} xs={12}>
                <div style={{ width: "100%" }}>
                  <div style={{ marginTop: 8 }}>
                    <Select.Root
                      error={
                        inputError.includes("primaryMetric")
                          ? intl.formatMessage({
                              id: "create_and_update_experiment.error_messages.primary_metric",
                            })
                          : ""
                      }
                      label={`${intl.formatMessage({ id: "create_and_update_experiment.labels.step1.primary_metrics" })}`}
                      onChange={(e: any) =>
                        handleOnChangeForm("primaryMetric", e)
                      }
                      placeholder="Select"
                      shape="square"
                      name="primaryMetric"
                      hint={`${intl.formatMessage({ id: "create_and_update_experiment.hints.experiment_primary_metrics" })}`}
                      value={
                        experimentMutation.basicInformation?.primaryMetric || ""
                      }
                      data-testid="input-primary-metrics"
                    >
                      {primaryMetric?.map((item, index) => (
                        <Select.Option
                          key={`${item.id}-${index}`}
                          value={item.id}
                          data-testid={`primary-metric-${item.name}`}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select.Root>
                  </div>
                </div>
              </Item>
              <Item lg={6} md={6} sm={12} xl={6} xs={12}>
                <div style={{ width: "100%" }}>
                  <div style={{ marginTop: 8, width: "100%" }}>
                    <Select.Root
                      error={
                        inputError.includes("secondaryMetrics")
                          ? intl.formatMessage({
                              id: "create_and_update_experiment.error_messages.secondary_metrics",
                            })
                          : ""
                      }
                      label={`${intl.formatMessage({ id: "create_and_update_experiment.labels.step1.secondary_metrics" })}`}
                      onChange={(e: any) =>
                        handleOnChangeForm("secondaryMetrics", e)
                      }
                      placeholder="Select"
                      multiple
                      shape="square"
                      name="secondaryMetrics"
                      value={
                        experimentMutation.basicInformation?.secondaryMetrics
                      }
                      hint={`${intl.formatMessage({ id: "create_and_update_experiment.hints.experiment_secondary_metrics" })}`}
                      data-testid="input-secondary-metrics"
                    >
                      {secondaryMetrics?.map((item, index) => (
                        <Select.Option
                          key={`${item.id}-${index}`}
                          value={item.id}
                          data-testid={`secondary-metric-${item.name}`}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select.Root>
                  </div>
                </div>
              </Item>
            </Container>
            <Container
              style={{
                width: "100%",
              }}
            >
              <Item
                lg={6}
                md={6}
                sm={12}
                xl={6}
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 30,
                }}
              >
                <Paragraph size="small" weight="semibold">
                  <FormattedMessage id="create_and_update_experiment.labels.step1.guardrails" />
                </Paragraph>

                <div
                  style={{
                    display: "flex",
                    flexBasis: "100%",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                  }}
                  data-testid="div-select-viewers"
                >
                  {guardrails?.requiredGuardrails?.map((item) => (
                    <div
                      style={{
                        marginTop: 10,
                      }}
                      key={item.id}
                    >
                      <Chip.Root
                        disabled={true}
                        style={{ marginRight: 10, color: "#000" }}
                      >
                        <Chip.Label data-testid={`chip-guardrail-${item.id}`}>
                          {item.name}
                        </Chip.Label>
                      </Chip.Root>
                    </div>
                  ))}
                </div>
              </Item>
            </Container>
          </Card>
        </Item>
      </Item>
    </Fragment>
  );
};

export default Step1;
