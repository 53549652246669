export const EXPERIMENT_MOCK = {
  id: "66169f4314225f2de5a695d9",
  name: "Lorem ipsum dolor",
  description:
    "Gostaria de enfatizar que a estrutura atual da organização obstaculiza a apreciação da importância das formas de ação.",
  hypothesis:
    "Gostaria de enfatizar que a estrutura atual da organização obstaculiza a apreciação da importância das formas de ação.",
  status: null,
  documentationLink: "https://lerolero.com/",
  country: "3",
  vendorId: null,
  externalId: null,
  startDate: null,
  endDate: null,
  primaryMetrics: ["primaryMetric"],
  secondaryMetrics: ["secondaryMetric"],
  guardrailsIds: ["Total AOV", "Add to cart ABI", "Custom Guardrail 1"],
  audiences: [
    {
      externalId: "0",
      name: "Option 1",
      trafficPercentage: 50,
    },
  ],
  variations: [],
};

export const baseExperiment = {
  name: "",
  description: "",
  hypothesis: "",
  countryAsAudience: false,
  countryTrafficPercentage: null,
  documentationLink: "",
  country: "",
  guardrailsIds: [],
  variations: [],
  primaryMetrics: [],
  secondaryMetrics: [],
};
