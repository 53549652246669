import {
  Button,
  Dialog,
  Heading,
  Paragraph,
  TextLink,
} from "@hexa-ui/components";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";

type CustomNameGuidelineDialogProps = {
  openDialog: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  dataTestid?: string;
};

const CustomNameGuidelineDialog = ({
  openDialog,
  setOpened,
  dataTestid,
}: CustomNameGuidelineDialogProps) => {
  const translateKey = [
    "create_and_update_experiment.labels.step1.modal.name_guideline.text.description1",
    "create_and_update_experiment.labels.step1.modal.name_guideline.text.description2",
    "create_and_update_experiment.labels.step1.modal.name_guideline.text.description3",
    "create_and_update_experiment.labels.step1.modal.name_guideline.text.description4",
  ];

  const missingMessage = "create_and_update_experiment";
  const intl = useIntl();

  const hasTranslation = useCallback((id): boolean => {
    const formattedMessage = intl.formatMessage({ id }, { missingMessage });
    return !formattedMessage.includes(missingMessage);
  }, []);

  /*
  const <TextLink target="blank" href="https://cxl.com/ab-test-calculator/">
  <FormattedMessage id="experiment_details.edit_estimated_end_date.cxl_calculator.link" />
</TextLink>*/

  return (
    <div data-testid={dataTestid}>
      <Dialog.Root
        data-testid="create__name-guideline-dialog"
        closeButton={false}
        actions={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              data-testid={`create_name_guideline-close`}
              onClick={() => setOpened(false)}
              size="medium"
              variant="primary"
            >
              <FormattedMessage
                id={`create_and_update_experiment.labels.step1.modal.name_guideline.actions.close_button`}
              />
            </Button>
          </div>
        }
        title={
          <Heading width="601px" size="H2">
            <FormattedMessage
              id={`create_and_update_experiment.labels.step1.modal.name_guideline.title`}
            />
          </Heading>
        }
        open={openDialog}
      >
        <Paragraph style={{ width: "553px" }}>
          <FormattedMessage
            id={`create_and_update_experiment.labels.step1.modal.name_guideline.description`}
          />
          <div style={{ fontWeight: "bold", marginBottom: 16 }}>
            <FormattedMessage
              id={`create_and_update_experiment.labels.step1.modal.name_guideline.description2`}
            />
          </div>
          <FormattedMessage
            id={`create_and_update_experiment.labels.step1.modal.name_guideline.description3`}
          />
        </Paragraph>
        <Paragraph>
          <ol style={{ listStyle: "none", paddingLeft: "0px" }}>
            {translateKey.map((tKey) => {
              return (
                <li key={tKey} style={{ marginBottom: 16 }}>
                  <div style={{ fontWeight: "bold" }}>
                    <FormattedMessage id={`${tKey}.title`} />
                  </div>
                  <div>
                    <FormattedMessage id={`${tKey}.description`} />
                    {hasTranslation(`${tKey}.link`) && (
                      <TextLink
                        target="blank"
                        href={intl.formatMessage({ id: `${tKey}.link_url` })}
                      >
                        <FormattedMessage id={`${tKey}.link`} />
                      </TextLink>
                    )}
                  </div>
                </li>
              );
            })}
          </ol>
        </Paragraph>
      </Dialog.Root>
    </div>
  );
};

export default CustomNameGuidelineDialog;
