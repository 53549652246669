import { Grid } from "@hexa-ui/components";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import CustomHeading from "../../../components/CustomHeading/CustomHeading";

type CustomPageHeaderProps = {
  title?: string;
  translatedTitleKey?: string;
  actions?: JSX.Element;
};

const CustomPageHeader = ({
  title,
  translatedTitleKey,
  actions,
}: CustomPageHeaderProps) => {
  const { Item } = Grid;
  return (
    <Item
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px 0",
      }}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      xs={12}
    >
      <BreadCrumb />
      <Item
        sm={12}
        md={12}
        lg={12}
        xl={12}
        xs={12}
        style={{
          padding: 0,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <CustomHeading transllatedTitleKey={translatedTitleKey} title={title} />
        {actions}
      </Item>
    </Item>
  );
};

export default CustomPageHeader;
