import { Dialog, LoadingDots, Paragraph } from "@hexa-ui/components";
import { FormattedMessage } from "react-intl";
import CustomHeading from "../CustomHeading/CustomHeading";
import { dialogContentCss } from "./styles";

export type ExportFileDialogProps = {
  open: boolean;
  title: string;
  messages: string[];
  close: () => void;
};

const ExportFileDialog = ({
  open,
  title,
  messages,
  close,
}: ExportFileDialogProps) => {
  return (
    <Dialog.Root
      contentCss={dialogContentCss}
      closeButton={true}
      onClose={close}
      open={open}
    >
      <LoadingDots />
      {title && (
        <CustomHeading
          customStyle={{ fontFamily: "Barlow" }}
          transllatedTitleKey={`export_file.${title}`}
        />
      )}

      {messages.map((item) => {
        return (
          <Paragraph style={{ fontFamily: "Work Sans" }}>
            <FormattedMessage id={`export_file.${item}`} />
          </Paragraph>
        );
      })}
    </Dialog.Root>
  );
};

export default ExportFileDialog;
