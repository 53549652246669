import { Alert, Grid, Heading, Paragraph } from "@hexa-ui/components";
import { FormattedMessage, useIntl } from "react-intl";
import { formatedDate } from "../../../services/utils";
import { IActionLink } from "../../../types/common";
import { IExperiment } from "../../../types/experiment";
import CardHeading from "../../CardHeading/CardHeading";

const ExperimentLaunch = ({
  experiment,
  action,
}: {
  experiment: IExperiment;
  action?: IActionLink;
}): JSX.Element => {
  const { Item } = Grid;

  const intl = useIntl();

  return (
    <Item sm={12} style={{ display: "flex", flexDirection: "column" }}>
      <CardHeading
        heading={
          <Heading size="H3">
            <FormattedMessage id="experiment_details.experiment_launch.experiment_launch" />
          </Heading>
        }
        action={action}
      />
      <Item lg={12} md={12} sm={12} xl={12} xs={12}>
        <Alert
          style={{ width: "100%", marginTop: 20 }}
          message={`${intl.formatMessage({ id: "experiment_details.experiment_launch.info_message" })}`}
        />
      </Item>
      <Item sm={12} style={{ display: "flex" }}>
        <Item lg={4} md={4} sm={4} xl={4} xs={4}>
          <div style={{ width: "100%" }}>
            <div style={{ marginTop: 30 }}>
              <Heading size="H5">
                <FormattedMessage id="experiment_details.experiment_launch.start_date" />
              </Heading>
              <Paragraph>{formatedDate(experiment.startDate)}</Paragraph>
            </div>
          </div>
        </Item>
        <Item lg={3} md={3} sm={12} xl={3} xs={12}>
          <div style={{ width: "100%" }}>
            <div style={{ marginTop: 30 }}>
              <Heading size="H5">
                <FormattedMessage id="experiment_details.experiment_launch.estimated_end_date" />
              </Heading>
              <Paragraph>
                <FormattedMessage id="experiment_details.experiment_launch.estimated_end_date_text" />
              </Paragraph>
            </div>
          </div>
        </Item>
        <Item lg={6} md={6} sm={12} xl={6} xs={12}>
          <div style={{ width: "100%" }}>
            <div style={{ marginTop: 30 }}>
              <Heading size="H5">
                <FormattedMessage id="experiment_details.experiment_launch.duration" />
              </Heading>
              <Paragraph>
                <FormattedMessage id="experiment_details.experiment_launch.duration_text" />
              </Paragraph>
            </div>
          </div>
        </Item>
      </Item>
    </Item>
  );
};
export default ExperimentLaunch;
