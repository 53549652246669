import { useQuery } from "react-query";
import { getExperimentById } from "../../../services/experiment/experimentService";

export const useGetSingleExperimentQuery = (experimentId: string) => {
  const query = useQuery({
    queryFn: () => getExperimentById(experimentId),
    queryKey: ["experiment", experimentId],
    enabled: !!experimentId,
    refetchOnWindowFocus: false,
    retry: 5,
  });

  return query;
};
