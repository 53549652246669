import Api from "../../Api/Api";
import { envs } from "../../config/env";
import { Guardrails } from "../../types/guardrails";

export async function getGuardrails(): Promise<Guardrails> {
  try {
    const response = await Api.get({
      url: `${envs.BASE_URL}/guardrails`,
    });

    return response.data;
  } catch (error) {
    console.error("Error retrieving experiment:", error);
    throw error;
  }
}
