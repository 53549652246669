import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Router from "./Router";
import { IntlProvider } from "./i18n";
import { persistor, store } from "./store";
import { EnvConfig, EnvProvider } from "./utils/envProvider";

export default function App(props: EnvConfig) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <EnvProvider env={props}>
          <IntlProvider>
            <Router />
          </IntlProvider>
        </EnvProvider>
      </PersistGate>
    </Provider>
  );
}
