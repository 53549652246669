import { IActionLink } from "@/types/common";
import { Grid, TextLink } from "@hexa-ui/components";
import { useCustomHistory } from "../../utils/routes";
import "../style.css";

const CardHeading = ({
  heading,
  action,
}: {
  heading: JSX.Element;
  action?: IActionLink;
}) => {
  const { Container, Item } = Grid;
  const navigate = useCustomHistory();

  return (
    <Item sm={12} className="card-heading-align">
      {heading}
      {action && (
        <TextLink
          data-testid="card-heading-action-link"
          className="default-link medium-link"
          onClick={() =>
            action.link ? navigate?.goTo(action.link) : action.stateModal(true)
          }
        >
          {action.icon}

          <span style={{ paddingTop: "4px" }}>{action?.content}</span>
        </TextLink>
      )}
    </Item>
  );
};

export default CardHeading;
