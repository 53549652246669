import { styled } from "@hexa-ui/theme";

export const DialogUlList = styled("ul", {
  listStyle: "none",
  padding: "0",
  margin: "0",
  height: "152px",
  gap: "8px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const DialogContentContainer = styled("div", {
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignContent: "space-between",
  width: "671px",
  height: "448px",
});

export const DialogContentHeader = styled("div", {
  height: "32px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const InputRadio = styled("input", {
  border: "1px solid #ccc",
  borderRadius: "50%",
  width: "24px",
  height: "24px",
  margin: 0,
  padding: 0,
  outline: "none",
});

export const cssProps = {
  DialogCardCss: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "72px",
    padding: "16px",
    border: "#141414",
    borderRadius: "8px",
  },
};
