import { TypeToast } from "admin-portal-shared-services";
import { useState } from "react";
import { CustomToastConfig } from "../../components/CustomToast/types";
type Toast = {
  isOpened: boolean;
  message?: string;
  duration?: number;
  type?: TypeToast;
};

type ToastProps = {
  config: Toast;
};

const useToastConfig = () => {
  const [toast, setToast] = useState<CustomToastConfig>({
    isOpened: false,
    type: TypeToast.INFO,
    message: "",
    duration: 3000,
  });

  const handleToast = (
    message: string,
    isOpened: boolean,
    type?: TypeToast,
    duration?: number
  ) => {
    setToast({
      isOpened,
      type,
      message,
      duration,
    });
  };

  const handleCloseToast = () => {
    setToast({
      isOpened: false,
    });
  };

  return {
    toast,
    handleToast,
    handleCloseToast,
  };
};

export default useToastConfig;
