import { ProgressTracker } from "@hexa-ui/components";

const Stepper = ({
  tabs,
  completedSteps,
  biggestStepActive,
  currentStep,
}): JSX.Element => {
  const { Root, Header, Step, StepLabel } = ProgressTracker;

  return (
    <div className="test">
      <ProgressTracker.Root
        currentStep={currentStep}
        orientation="horizontal"
        size="large"
      >
        <ProgressTracker.Header>
          {tabs.map((item, index) => (
            <ProgressTracker.Step
              index={index}
              key={index}
              completed={index < currentStep}
              disabled={index > currentStep}
            >
              <ProgressTracker.StepLabel
                alertMessage={item.alertMessage}
                label={item.label}
                detail={item.detail}
              />
            </ProgressTracker.Step>
          ))}
        </ProgressTracker.Header>
      </ProgressTracker.Root>
    </div>
  );
};

export default Stepper;
