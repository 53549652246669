import React, { createContext, useContext, useState } from "react";
import { Metric } from "../../types/metrics";

type MetricProviderProps = {
  children: React.ReactNode;
};

type MetricProviderType = {
  primaryMetrics: Metric[];
  secondaryMetrics: Metric[];
  handlePrimaryMetrics: (metrics: Metric[]) => void;
  handleSecondaryMetrics: (metrics: Metric[]) => void;
};

export const MetricContext = createContext<MetricProviderType>({
  primaryMetrics: [],
  secondaryMetrics: [],
  handlePrimaryMetrics: () => {},
  handleSecondaryMetrics: () => {},
} as MetricProviderType);

export const useMetrics = () => useContext(MetricContext);

export const MetricProvider: React.FC<MetricProviderProps> = ({ children }) => {
  const [primaryMetrics, setPrimaryMetrics] = useState<Metric[]>([]);
  const [secondaryMetrics, setSecondaryMetrics] = useState<Metric[]>([]);

  const handlePrimaryMetrics = (metrics: Metric[]) => {
    setPrimaryMetrics(metrics);
  };

  const handleSecondaryMetrics = (metrics: Metric[]) => {
    setSecondaryMetrics(metrics);
  };

  return (
    <MetricContext.Provider
      value={{
        primaryMetrics,
        secondaryMetrics,
        handlePrimaryMetrics,
        handleSecondaryMetrics,
      }}
    >
      {children}
    </MetricContext.Provider>
  );
};

export default MetricProvider;
