import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IExperimentMutationForm } from "../../../store/interfaces/experimentMutation/experimentMutation";

export const initialState: IExperimentMutationForm = {
  basicInformation: {
    name: "",
    description: "",
    hypothesis: "",
    documentationLink: "",
    country: "",
    primaryMetric: "",
    secondaryMetrics: [],
    customGuardrails: [],
    requiredGuardrails: [],
    externalKey: "",
  },
  audiences: {
    audiences: {
      externalId: "",
      name: "",
    },
    countryAsAudience: false,
    trafficPercentage: 0,
  },
  variations: [],
};

const experimentMutationSlice = createSlice({
  name: "experimentMutation",
  initialState,
  reducers: {
    setExperimentMutation(
      state,
      action: PayloadAction<IExperimentMutationForm>
    ) {
      return { ...state, ...action.payload };
    },
    resetExperimentMutation(state) {
      return { ...state, ...initialState, id: undefined };
    },
  },
});

export const { setExperimentMutation, resetExperimentMutation } =
  experimentMutationSlice.actions;

export default experimentMutationSlice.reducer;
