import { Container } from "@admin-portal-shared-components/grid";
import {
  Action,
  Badge,
  Grid,
  Heading,
  TextLink,
  Tooltip,
} from "@hexa-ui/components";
import { BarChart2, Copy } from "@hexa-ui/icons";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Alerts from "../../../components/Alerts/Alerts";
import { handleCopyText } from "../../../pages/ExperimentMutation/utils";
import { getExperimentStatus } from "../../../services/utils";
import "../../../style.css";
import {
  ExperimentStatusList,
  ExperimentStatusProp,
  IExperiment,
} from "../../../types/experiment";
import { useEnvProvider } from "../../../utils/envProvider";
import { ExperimentDetailsContainer } from "./styles";

type ExperimentStatusProps = {
  experiment: IExperiment;
  actions?: [firstAction: Action, secondAction?: Action];
};

const ExperimentStatus = ({
  experiment,
  actions,
}: ExperimentStatusProps): JSX.Element => {
  const env = useEnvProvider();
  const metricReports = useMemo(
    () => env?.env?.metricReports,
    [env?.env?.metricReports]
  );
  const { Item } = Grid;
  const intl = useIntl();

  const getStatusBadge = (status: ExperimentStatusProp) => {
    if (status === undefined)
      return (
        <Badge.Status
          className="mt-2"
          key={"-"}
          color={"gray" as any}
          label={"-"}
        />
      );
    const statusValues = getExperimentStatus(status);

    return (
      <Badge.Status
        className="mt-2"
        key={statusValues.label}
        color={statusValues.color as any}
        label={`● ${intl.formatMessage({ id: statusValues.label })}`}
      />
    );
  };

  const [copied, setCopied] = useState("copy_external_key");

  const handleClipboard = (text: string) => {
    handleCopyText(text);
    setCopied("copied");
    setTimeout(() => {
      setCopied("copy_external_key");
    }, 3000);
  };

  const pauseExperiment = () => {
    return alert("clicked");
  };

  const isInValidationStatus =
    experiment?.status === ExperimentStatusList.IN_VALIDATION;

  return (
    <ExperimentDetailsContainer
      css={{ $$height: isInValidationStatus ? "184px" : "auto" }}
    >
      {isInValidationStatus && (
        <Container style={{ width: "100%" }}>
          <Alerts
            type="info"
            message="This experiment's is in test version. You can either pause it or publish it to the entire selected audience."
            actions={actions}
          />
        </Container>
      )}
      <Container style={{ display: "flex", flexDirection: "row" }}>
        <Item lg={2} md={2} sm={12} xl={2} xs={12}>
          <div style={{ width: "100%" }}>
            <div>
              <Heading size="H5">
                <FormattedMessage id="experiment_details.status.status" />
              </Heading>
              {getStatusBadge(experiment?.status as ExperimentStatusProp)}
            </div>
          </div>
        </Item>
        <Item lg={6} md={6} sm={12} xl={6} xs={12}>
          <div style={{ width: "100%" }}>
            <div>
              <Heading size="H5">
                <FormattedMessage id="experiment_details.external_key.label" />
              </Heading>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip
                  text={intl.formatMessage({
                    id: `experiment_details.external_key.${copied}`,
                  })}
                  placement="top"
                >
                  {experiment?.externalKey}
                  <Copy
                    onClick={() => handleClipboard(experiment?.externalKey)}
                    style={{ cursor: "pointer", color: "#0363c4" }}
                    size="large"
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </Item>
        {/*
        =TODO
        Postponed to after MVP
        <Item lg={2} md={2} sm={12} xl={2} xs={12}>
          <div style={{ width: "100%" }}>
            <div>
              <Heading size="H5" style={{ display: "flex" }}>
                <FormattedMessage id="experiment_details.status.confidence_level" />{" "}
                <Tooltip
                  text={intl.formatMessage({
                    id: "experiment_details.status.tooltip_text",
                  })}
                  placement="right"
                >
                  <Info style={{ position: "relative", bottom: "3px" }} />
                </Tooltip>
              </Heading>
              {/* <Paragraph data-testid="percentage-rollout-value">
                {(!experiment?.percentageRollOut ||
                  experiment?.percentageRollOut <= 0) &&
                experiment?.status === "DRAFT"
                  ? "-"
                  : `${experiment?.percentageRollOut}%`}
              </Paragraph> }
            </div>
          </div>
        </Item>
      */}
        {/* <Item lg={4} md={4} sm={12} xl={4} xs={12}>
          <div style={{ width: "100%" }}></div>
        </Item> */}
        <Item lg={2} md={2} sm={12} xl={2} xs={12}>
          <div
            style={{
              width: "100%",
            }}
          >
            {experiment?.status !== "DRAFT" && (
              <TextLink
                href={`${metricReports.experiment}`}
                className="default-link medium-link"
                target="_blank"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1x solid",
                  }}
                >
                  <BarChart2 size="large" />
                  <FormattedMessage id="experiment_details.status.go_to_reports" />
                </div>
              </TextLink>
            )}
          </div>
        </Item>
      </Container>
    </ExperimentDetailsContainer>
  );
};
export default ExperimentStatus;
