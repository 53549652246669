import { IExperiment } from "@/types/experiment";
import { Card, Grid } from "@hexa-ui/components";
import { useMemo } from "react";
import AudienceSelect from "../../../components/ExperimentCommon/AudienceSelect/AudienceSelect";
import BasicInformation from "../../../components/ExperimentCommon/BasicInformation/BasicInformation";
import ExperimentLaunch from "../../../components/ExperimentCommon/ExperimentLaunch/ExperimentLaunch";
import Metrics from "../../../components/ExperimentCommon/Metrics/Metrics";
import Variants from "../../../components/ExperimentCommon/Variants/Variants";
import useResizeObserverErrorHandler from "../../../hooks/useResizeObserverErrorHandler/useResizeObserverErrorHandler";
import { VARIANT_TYPE } from "../../../pages/ExperimentMutation/utils";
import { useExperimentMutation } from "../../../store/hooks/ExperimentMutation/ExperimentMutationHook";
import { useEnvProvider } from "../../../utils/envProvider";

const Step4 = (): JSX.Element => {
  const { Container, Item } = Grid;
  useResizeObserverErrorHandler();
  const env = useEnvProvider();
  const vendorId = useMemo(() => env?.env?.vendorId, [env?.env?.vendorId]);

  const {
    experimentMutation: { basicInformation, audiences, variations: variants },
  } = useExperimentMutation();

  const experiment: IExperiment = {
    ...basicInformation,
    externalKey: basicInformation.externalKey,
    id: "",
    countryAsAudience: audiences.countryAsAudience,
    vendorId: vendorId[basicInformation.country],
    audiences: [
      {
        ...audiences.audiences,
        trafficPercentage: audiences.trafficPercentage,
      },
    ],
    countryTrafficPercentage: audiences.trafficPercentage,

    primaryMetrics: [basicInformation.primaryMetric],
    guardrailsIds: basicInformation.requiredGuardrails,
    variations: [
      ...variants.map((variant) => ({
        id: variant.id,
        name: variant.name,
        description: variant.description,
        configObject:
          variant?.type === VARIANT_TYPE.APPLICATION_CODED
            ? variant.json
            : null,
      })),
    ],
  };

  return (
    <Container className="w-full" data-testid="step-4">
      <Item sm={12}>
        <Card
          ref={{
            current: "[Circular]" as any,
          }}
          border="medium"
          elevated="small"
          style={{
            marginTop: 50,
            padding: 20,
            width: "100%",
          }}
        >
          <BasicInformation
            experiment={experiment}
            isPreview
            /*
              =TODO 
              Disabled till after MVP
              action={{
              content: "Edit",
              link: `${formData?.id}`,
              icon: <Edit2 />,
            }}*/
          />
        </Card>
      </Item>
      <Item sm={12}>
        <Card
          ref={{
            current: "[Circular1]" as any,
          }}
          border="medium"
          elevated="small"
          style={{
            marginTop: 50,
            padding: 20,
            width: "100%",
          }}
        >
          <Metrics
            experiment={experiment}
            /*action={{
              content: "Edit",
              link: `${formData?.id}`,
              icon: <Edit2 />,
            }}*/
          />
        </Card>
      </Item>
      <Item sm={12} style={{ width: "100%" }}>
        <Card
          ref={{
            current: "[Circular]" as any,
          }}
          border="medium"
          elevated="small"
          style={{
            marginTop: 50,
            padding: 20,
            width: "100%",
          }}
        >
          <AudienceSelect
            experiment={experiment}
            /*action={{
              content: "Edit",
              link: `${formData?.id}`,
              icon: <Edit2 />,
            }}*/
          />
        </Card>
      </Item>
      <Item sm={12}>
        <Card
          ref={{
            current: "[Circular]" as any,
          }}
          border="medium"
          elevated="small"
          style={{
            marginTop: 50,
            padding: 20,
            width: "100%",
          }}
        >
          <Variants
            experiment={experiment}
            /*action={{
              content: "Edit",
              link: `${formData?.id}`,
              icon: <Edit2 />,
            }}*/
          />
        </Card>
      </Item>
      <Item sm={12}>
        <Card
          ref={{
            current: "[Circular]" as any,
          }}
          border="medium"
          elevated="small"
          style={{
            marginTop: 50,
            padding: 20,
            width: "100%",
          }}
        >
          <ExperimentLaunch experiment={experiment} />
        </Card>
      </Item>
    </Container>
  );
};

export default Step4;
