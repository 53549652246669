import { Grid, Image, Paragraph } from "@hexa-ui/components";
import EmptyImage from "../../../assets/images/no-data-available.png";

const EmptySection = ({ header, message }): JSX.Element => {
  const { Container, Item } = Grid;

  return (
    <>
      <Item sm={12} style={{ marginTop: 20 }}>
        <Container>
          <Item sm={12} style={{ justifyContent: "center" }}>
            <Image src={EmptyImage} />
          </Item>
          <Item sm={12} style={{ marginTop: "20px", justifyContent: "center" }}>
            <Paragraph
              style={{
                display: "flex",
                flexDirection: "row",
                width: "400px",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {message}
            </Paragraph>
          </Item>
        </Container>
      </Item>
    </>
  );
};

export default EmptySection;
