import { useSelector } from "react-redux";
import { IExperimentMutationState } from "../../../store/interfaces/experimentMutation/experimentMutation";

export const useExperimentMutation = () => {
  const experimentMutation = useSelector(
    (state: IExperimentMutationState) => state.experimentMutation
  );

  return {
    experimentMutation,
  };
};
