import { Toast } from "@hexa-ui/components";
import { TypeToast } from "admin-portal-shared-services";

export type CustomToastProps = {
  isOpened: boolean;
  dataTestid: string;
  type?: TypeToast;
  message?: string;
  duration?: number;
  showCloseButton?: boolean;
  onClose?: () => void;
};

const CustomToast = ({
  duration,
  isOpened,
  message,
  type,
  showCloseButton,
  onClose,
}: CustomToastProps) => {
  return (
    <Toast.Provider duration={duration ?? 3000} swipeDirection="right">
      <Toast.Root
        showCloseButton={showCloseButton}
        type={type}
        position="top-right"
        open={isOpened}
        message={message}
        onOpenChange={() => {
          onClose();
        }}
        onClose={() => {
          onClose();
        }}
        data-testid="new-experiment-toast"
      />
    </Toast.Provider>
  );
};

export default CustomToast;
