import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { exportExperimentJson } from "../../../../services/experiment/experimentService";

export const useExportExperimentQuery = () => {
  const [experimentId, setExperimentId] = useState<string>("");

  const queryClient = useQueryClient();

  const { isLoading, isSuccess, isError, refetch, data } = useQuery({
    queryFn: () => exportExperimentJson(experimentId),
    queryKey: ["export-experiment", experimentId],
    enabled: false,
    refetchOnWindowFocus: false,
    retry: 5,
  });

  const exportJson = (experimentId: string) => {
    setExperimentId(experimentId);
  };

  const handleCancelExperimentExportRequest = () => {
    queryClient.cancelQueries(["export-experiment", experimentId]);
  };

  useEffect(() => {
    if (!!experimentId) refetch();
  }, [experimentId]);

  return {
    isLoading,
    isSuccess,
    isError,
    exportJson,
    data,
    setExperimentId,
    experimentId,
    handleCancelExperimentExportRequest,
  };
};
