import Api from "../../Api/Api";
import { envs } from "../../config/env";
import { Metrics } from "../../types/metrics";

export async function getMetrics(): Promise<Metrics> {
  try {
    const response = await Api.get({
      url: `${envs.BASE_URL}/metrics`,
    });
    return response.data;
  } catch (error) {
    console.error("Error retrieving experiment:", error);
    throw error;
  }
}
