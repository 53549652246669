import { IActionLink } from "@/types/common";
import { IExperiment } from "@/types/experiment";
import { Chip, Grid, Heading } from "@hexa-ui/components";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { getGuardrails } from "../../../services/guardrails/guardrails";
import { getMetrics } from "../../../services/metrics/metrics";
import CardHeading from "../../CardHeading/CardHeading";

const Metrics = ({
  experiment,
  action,
}: {
  experiment: IExperiment;
  action?: IActionLink;
}): JSX.Element => {
  const { Item } = Grid;

  const { data: dataGuardrails } = useQuery({
    queryFn: () => getGuardrails(),
    queryKey: ["guardrails"],
    retry: 5,
  });

  const { data: metrics } = useQuery({
    queryFn: () => getMetrics(),
    queryKey: ["metrics"],
    retry: 5,
  });

  return (
    <Item sm={12} style={{ display: "flex", flexDirection: "column" }}>
      <CardHeading
        heading={<Heading size="H4">Metrics</Heading>}
        action={action}
      />
      <Item lg={12} md={12} sm={12} xl={2} xs={12} style={{ padding: 0 }}>
        <Item lg={6} md={6} sm={12} xl={6} xs={12}>
          <div style={{ width: "100%" }}>
            <div style={{ marginTop: 30 }}>
              <Heading size="H5">
                <FormattedMessage id="experiment_details.metrics.primary_metric" />
              </Heading>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <Chip.Root
                  disabled={true}
                  style={{ marginRight: 10, marginTop: 10 }}
                >
                  <Chip.Label data-testid={`primary-metric`}>
                    {metrics?.primaryMetrics.find(
                      (metric) => metric.id === experiment?.primaryMetrics[0]
                    )?.name || "-"}
                  </Chip.Label>
                </Chip.Root>
              </div>
            </div>

            <div style={{ marginTop: 30 }}>
              <Heading size="H5">
                <FormattedMessage id="experiment_details.metrics.secondary_metric" />
              </Heading>
              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
                data-testid="secondary-metric"
              >
                {experiment?.secondaryMetrics?.map((item, index) => (
                  <Chip.Root
                    style={{ marginRight: 10, marginTop: 10 }}
                    key={`${item}-${index}`}
                    disabled={true}
                  >
                    <Chip.Label data-testid={`secondary-metric`}>
                      {metrics?.secondaryMetrics.find(
                        (metric) => metric.id === item
                      )?.name || "-"}
                    </Chip.Label>
                  </Chip.Root>
                ))}
              </div>
            </div>
          </div>
        </Item>
        <Item lg={6} md={6} sm={12} xl={6} xs={12}>
          <div style={{ width: "100%" }}>
            <div style={{ marginTop: 30 }}>
              <Heading size="H5">
                <FormattedMessage id="experiment_details.metrics.guardrails" />
              </Heading>
              <div
                style={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
                data-testid="guardrails"
              >
                {dataGuardrails?.requiredGuardrails.map((item, index) => (
                  <Chip.Root
                    style={{ marginRight: 10, marginTop: 10 }}
                    key={`${item}-${index}`}
                    disabled={true}
                  >
                    <Chip.Label data-testid={`required-guardrails`}>
                      {item.name}
                    </Chip.Label>
                  </Chip.Root>
                ))}
              </div>
            </div>

            {/* 
            =TODO

            This information is not going to be show
            on MVP phasis.
            <div style={{ marginTop: 30 }}>
              <Heading size="H5">
                <FormattedMessage id="experiment_details.metrics.custom_guardrails" />
              </Heading>
              <div
                style={{ marginTop: 10, display: "flex" }}
                data-testid="custom-guardrails"
              >
                {experiment?.guardrailsIds &&
                experiment?.guardrailsIds?.length > 0
                  ? dataGuardrails?.customGuardrails
                      .filter((data) =>
                        experiment.guardrailsIds.includes(data.id)
                      )
                      .map((item, index) => (
                        <Chip.Root
                          style={{ marginRight: 10 }}
                          key={`${item}-${index}`}
                        >
                          <Chip.Label data-testid={`custom-guardrails`}>
                            {item.name}
                          </Chip.Label>
                        </Chip.Root>
                      ))
                  : "-"}
              </div>
            </div>
            */}
          </div>
        </Item>
      </Item>
    </Item>
  );
};
export default Metrics;
