/*
    TODO: Should be refactored to not use hardcoded values
*/

export const tabsItems = [
  {
    label: "Basic Information",
    detail: "Add details and metrics.",
    alertMessage: "",
  },
  {
    label: "Audience",
    detail: "Set target users.",
    alertMessage: "",
  },
  {
    label: "Variants",
    detail: "Add experiment variants.",
    alertMessage: "",
  },
  {
    label: "Review",
    detail: "Review and Publish.",
    alertMessage: "",
  },
];
