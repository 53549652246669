import { FileUploader, FileValidated } from "@hexa-ui/components";
import { Upload } from "@hexa-ui/icons";
import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ReactNode, useState } from "react";

import { useDialog } from "../../context/DialogContext/dialogProvider";
import { useLoading } from "../../context/LoadingContext/loadingProvider";
import { baseExperiment } from "./../../mocks/experiment";
import { IExperiment } from "./../../types/experiment";
import { isJsonString } from "./../../utils/utils";
import "./CustomFileUpload.scss";

export type CustomFileUploadProps = {
  customFileUploadHeader?: AccordionHeaderProps;
  customFileUploadContent?: AccordionContentProps;
  expanded?: boolean;
  onUpload?: (uploadResponse: IExperiment) => void;
  onError?: (message: string) => void;
};

export enum FileTypes {
  JSON = ".json",
}

type AccordionHeaderProps = {
  description?: string;
  title?: string;
  icon?: ReactNode;
};

type AccordionContentProps = {
  accept?: FileTypes;
  message?: string;
  maxFileSize?: number;
  type?: string;
};

const CustomFileUpload = ({
  customFileUploadHeader,
  customFileUploadContent,
  expanded = false,
  onUpload,
  onError,
}: CustomFileUploadProps) => {
  const fileReader = new FileReader();
  const { openDialog, closeDialog } = useDialog();
  const { showLoading, hideLoading } = useLoading();
  const [isExpanded, setIsExpanded] = useState(expanded);

  const [currentFile, setCurrentFile] = useState<FileValidated[]>([]);

  const handleAccordionExpanded = () => setIsExpanded(!isExpanded);
  /* 
    Gonna handle the file upload here
  */
  const handleOnChange = (filesResponse: FileValidated[]) => {
    let receivedFile = filesResponse[filesResponse.length - 1] as FileValidated;
    if (currentFile.length > 0 && receivedFile.id !== currentFile[0]?.id) {
      openDialog(
        "Select another file?",
        "The new file will overwrite the current information of this experiment.",
        {
          action: () => {
            if (!validateFileSize(receivedFile.file)) {
              onError(
                `File import failed. Check file format and size, and try again.`
              );
              closeDialog();
              return;
            }

            setCurrentFile([receivedFile]);
            loadFileFromFileReader(receivedFile.file);
            closeDialog();
          },
          dialogActionText: "Select file",
          dialogActionButtonType: "primary",
        },
        {
          action: closeDialog,
          dialogActionText: "Cancel",
          dialogActionButtonType: "secondary",
        }
      );
      return;
    }

    if (!validateFileSize(receivedFile.file)) {
      onError(`File import failed. Check file format and size, and try again.`);
      return;
    }

    setCurrentFile([receivedFile]);
    loadFileFromFileReader(receivedFile.file);
  };

  const loadFileFromFileReader = (file: File) => {
    fileReader.readAsText(file as Blob);
    fileReader.onloadstart = () => {
      showLoading();
    };
    fileReader.onloadend = (evt: ProgressEvent<FileReader>) => {
      const result = evt.target?.result as string;
      hideLoading();

      if (!isJsonString(result)) {
        setCurrentFile([]);
        onError("Invalid file format");
        return;
      }

      const parsedResult = JSON.parse(result) as IExperiment;
      onUpload(parsedResult);
    };
  };

  const openDeleteDialog = () => {
    openDialog(
      "Delete file?",
      "You will delete the current information of this experiment.",
      {
        action: () => {
          handleConfirmDeleteFile();
          closeDialog();
        },
        dialogActionText: "Yes, delete",
        dialogActionButtonType: "destructive",
      },
      {
        action: () => closeDialog(),
        dialogActionText: "Cancel",
        dialogActionButtonType: "secondary",
      }
    );
  };

  const validateFileSize = (file: File) => {
    if (file.size > customFileUploadContent?.maxFileSize) {
      return;
    }

    return true;
  };

  const handleConfirmDeleteFile = () => {
    const basicData = baseExperiment;
    setCurrentFile([]);
    onUpload(basicData as IExperiment);
  };

  return (
    <div id="accordion" className="accordion">
      <Accordion expanded={isExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{
            border: "1px solid rgba(#141414, .1)",
          }}
          onClick={() => handleAccordionExpanded()}
        >
          <div className="custom--accordion">
            <span
              className="custom--accordion__icon"
              data-testid="custom--accordion__icon"
            >
              {
                /* Icon */
                customFileUploadHeader?.icon ? (
                  customFileUploadHeader.icon
                ) : (
                  <Upload size="xlarge" />
                )
              }
            </span>

            <div
              className="custom--accordion__holder"
              data-testid="custom--accordion__holder"
            >
              <h2 className="custom--accordion__title">
                {customFileUploadHeader?.title
                  ? customFileUploadHeader.title
                  : "Import experiment"}{" "}
                <span>(optional)</span>
              </h2>
              <div className="custom--accordion__description">
                {customFileUploadHeader?.description ? (
                  customFileUploadHeader.description
                ) : (
                  <p>
                    Import a JSON file from another experiment to reuse the
                    information (except the audience).
                  </p>
                )}
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <section
            data-testid="custom--accordion__details"
            className="custom--accordion__details"
          >
            <FileUploader
              message={
                customFileUploadContent?.message
                  ? customFileUploadContent.message
                  : "Drag and drop a JSON file here or click to upload."
              }
              accept={
                customFileUploadContent?.accept
                  ? customFileUploadContent.accept
                  : ".json"
              }
              maxFileSize={
                customFileUploadContent?.maxFileSize
                  ? customFileUploadContent.maxFileSize
                  : 1000000
              }
              type="file"
              maxFiles={1}
              data-testid="custom--file-uploader"
              onClean={() => openDeleteDialog()}
              value={currentFile}
              onChange={(uploadResponseFile: FileValidated[]) => {
                handleOnChange(uploadResponseFile);
              }}
            />
          </section>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CustomFileUpload;
