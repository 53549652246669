import {
  Button,
  Dialog,
  Heading,
  Paragraph,
  TextArea,
} from "@hexa-ui/components";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import { useUpdateExperiment } from "../../hooks/queries/experiments/useUpdateExperiment";
import { IExperiment } from "../../types/experiment";

const EnterResultsDialog = ({
  setEnterResultsOpen,
  enterResultsOpen,
  experiment,
  refetchGetExperimentById,
}: CustomDialogProps) => {
  const intl = useIntl();

  const dataUpdate = useUpdateExperiment(experiment?.id);

  const [result, setResults] = useState("");

  const [hasError, setHasError] = useState(false);

  const handleResultsChange = (event: any) => {
    event.preventDefault();
    const res = event.target.value;
    res === "" ? setHasError(true) : setHasError(false);
    setResults(res);
  };

  const handleConfirm = async (event: any) => {
    event.preventDefault();
    if (result === "") setHasError(true);
    else {
      setHasError(false);
      await dataUpdate.mutateAsync({ ...experiment, result });
      await refetchGetExperimentById();
      setEnterResultsOpen(false);
      setResults("");
    }
  };

  const handleCloseDialog = () => {
    setHasError(false);
    setEnterResultsOpen(false);
  };

  return (
    <>
      <Dialog.Root
        contentCss={{
          width: "865px",
          margin: "0 auto",
          padding: 0,
          overflow: "unset",
        }}
        closeButton={false}
        actions={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "865px",
            }}
          >
            <Button
              data-testid="enter-result-dialog-close"
              onClick={handleCloseDialog}
              size="medium"
              style={{ marginRight: "0.9375rem" }}
              variant="secondary"
            >
              <FormattedMessage
                id={`transactional_custom_dialog.result.cancel`}
              />
            </Button>

            <Button
              onClick={handleConfirm}
              data-testid="enter-result-continue-result"
              size="medium"
              variant="primary"
            >
              <FormattedMessage
                id={`transactional_custom_dialog.result.continue`}
              />
            </Button>
          </div>
        }
        title={
          <Heading width={"865px"} size="H2">
            <FormattedMessage
              id={`transactional_custom_dialog.result.header`}
            />
          </Heading>
        }
        open={enterResultsOpen}
      >
        <Paragraph
          style={{
            fontWeight: 600,
            fontSize: 14,
            color: hasError ? "red" : "black",
          }}
        >
          <FormattedMessage
            id={`transactional_custom_dialog.result.sub_header`}
          />
        </Paragraph>
        <Paragraph style={{ fontWeight: 400, fontSize: 12, color: "#757575" }}>
          <FormattedMessage
            id={`transactional_custom_dialog.result.explanation`}
          />
        </Paragraph>
        <Paragraph>
          <TextArea
            onChange={(e) => handleResultsChange(e)}
            style={{ height: "238px", width: "865px" }}
            characterCounter
            data-testid="text-area-with-counter"
            placeholder="Enter experiment findings"
            maxLength={500}
            required
            errorText={`${intl.formatMessage({ id: "transactional_custom_dialog.result.error_text" })}`}
            hasError={hasError}
          />
        </Paragraph>
      </Dialog.Root>
    </>
  );
};

type CustomDialogProps = {
  setEnterResultsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  enterResultsOpen: boolean;
  experiment: IExperiment;
  refetchGetExperimentById: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<IExperiment, unknown>>;
};

export default EnterResultsDialog;
