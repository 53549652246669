import { useEffect } from "react";

const useResizeObserverErrorHandler = () => {
  useEffect(() => {
    const handleError = (e) => {
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        resizeObserverErr.setAttribute("style", "display: none");
      }
    };

    window.addEventListener("error", handleError);

    return () => {
      window.removeEventListener("error", handleError);
    };
  }, []);
};

export default useResizeObserverErrorHandler;
