import { useAuthenticationService } from "admin-portal-shared-services";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";

export interface IRequest {
  url: string;
  body?: any;
  config?: any;
}

const initialAxios = Axios.create({ baseURL: "/" });

const authentication = useAuthenticationService();

const auth = localStorage.getItem("authHeader");

const customHeaders = {
  Authorization: auth,
  requestTraceId: `experimentation-plarform-mfe-${uuidv4()}`,
};

authentication.enhancedAxios(
  initialAxios as unknown as Parameters<typeof authentication.enhancedAxios>[0],
  {
    headers: [
      {
        ...customHeaders,
      },
    ],
  }
);

const Api = {
  post: ({ url, body, config }: IRequest): Promise<any> =>
    initialAxios.post(url, body, config).catch((error) => ({
      hasError: true,
      ...error,
    })),

  delete: ({ url, config }: IRequest): Promise<any> =>
    initialAxios
      .delete(url, config)
      .catch((error) => ({ hasError: true, ...error })),

  get: ({ url, config }: IRequest): Promise<any> =>
    initialAxios
      .get(url, config)
      .catch((error) => ({ hasError: true, ...error })),

  put: ({ url, body, config }: IRequest): Promise<any> =>
    initialAxios.put(url, body, config).catch((error) => ({
      hasError: true,
      ...error,
    })),

  patch: ({ url, body, config }: IRequest): Promise<any> =>
    initialAxios.patch(url, body, config).catch((error) => ({
      hasError: true,
      ...error,
    })),
};

export default Api;
